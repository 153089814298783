// @flow

/* ------------------ API Entities types --------------------- */

export type EventStateType = {
    entity: string,
    id: number,
    type: string,
    uiEntity: string,
    uiType: string,
    visible: boolean
};

export type TemplateGroupsStateType = {
    createdAt: string,
    id: string,
    name: string,
    updatedAt: string,
};

export type ConditionFieldOperandStateType = {
    operand: string,
    uiOperand: string,
}

export type ConditionFieldStateType = {
    id: number,
    name: string,
    operands: Array<ConditionFieldOperandStateType>,
    predefinedValues: Array<string>,
    referenceEntities: Array<string>,
    relatedEntity: string,
    type: string,
    uiName: string
};

export type ConditionFieldsState = {
    CUSTOMER?: Array<ConditionFieldStateType>,
    VENDOR?: Array<ConditionFieldStateType>,
    EMPLOYEE?: Array<ConditionFieldStateType>,
    CLASS?: Array<ConditionFieldStateType>,
    TRANSFER?: Array<ConditionFieldStateType>,
    TIME_ACTIVITY?: Array<ConditionFieldStateType>,
    ITEM?: Array<ConditionFieldStateType>,
    SALES_RECEIPT?: Array<ConditionFieldStateType>,
    PAYMENT?: Array<ConditionFieldStateType>,
    ACCOUNT?: Array<ConditionFieldStateType>,
    INVOICE?: Array<ConditionFieldStateType>,
    JOURNAL_ENTRY?: Array<ConditionFieldStateType>,
    BILL?: Array<ConditionFieldStateType>,
    BILL_PAYMENT?: Array<ConditionFieldStateType>,
    PURCHASE_ORDER?: Array<ConditionFieldStateType>,
    PURCHASE?: Array<ConditionFieldStateType>,
}

export type CommonMeta = {
    size: number;
    page: number;
    totalCount: number;
};

// Contacts

export type ContactItemType = {
    id: string,
    currentContact: boolean,
    displayName: string,
    phone?: string,
    email?: string
};

export type ContactStateType = {
    list: Array<ContactItemType>,
    meta: CommonMeta
};

export type ContactsStateType = {
    customers: ContactStateType,
    employees: ContactStateType,
    vendors: ContactStateType,
}

// Products

export type ProductItemType = {
    id: number,
    displayName: string
};

export type ProductsStateType = {
    list: Array<ProductItemType>,
    meta: CommonMeta
};

/* ------------------ FLow block/Operations types --------------------- */

export type BlockConditionStateType = {
    fieldsChain: {
        fieldsPath: Array<ConditionFieldStateType>
    },
    operand?: string,
    value?: Array<string | Object>
};

export type RecipientType = {
    type?: string,
    contact?: string
}

type MessageType = {
    text?: string,
    subject?: string,
}

export type BlockActiveActionStateType = {
    recipient: RecipientType,
    message: MessageType,
    type?: string,
    entity?: string,
    entityAction?: string,
    attachEntity?: boolean,
    cursorPosition: number,
    positionCursorOfRow: number,
}

/* ------------------ Flow tree --------------------- */

export type FlowActionBlockStateType = {
    flowNodeId: number,
    cellId: number,
    blockType: string,
    position: string,
    recipient: RecipientType,
    message: MessageType,
    type?: string,
    entity?: string,
    entityAction?: string,
    attachEntity?: boolean,
    nextAction?: FlowActionBlockStateType
}
export type FlowConditionBlockStateType = {
    flowNodeId: number,
    cellId: number,
    blockType: string,
    position: string,
    statement: BlockConditionStateType,
    nextBlockForTrue?: FlowActionBlockStateType | FlowConditionBlockStateType,
    nextBlockForFalse?: FlowActionBlockStateType | FlowConditionBlockStateType,
}

export type FlowBlockStateType =
    FlowActionBlockStateType
    | FlowConditionBlockStateType

export type FlowTreeStateType = {
    id?: string,
    companyId?: number | string,
    eventId?: number,
    name: string,
    status: string,
    executed?: boolean,
    templateId?: number,
    block: {} | FlowBlockStateType,
    blockCount: number
}

/* ------------------ Flow Matrix --------------------- */

export type MatrixCellChildCellType = {
    childId: number,
    position: string
}

export type CompletedMatrixCellStateType = {
    cellId: number,
    leftCellId: number,
    rightCellId: number,
    topCellId: number,
    bottomCellId: number,
    blockType: string,
    childCells: Array<MatrixCellChildCellType>,
    parentCellBlockType: string,
    parentPosition: string,
    flowNodeId: number,
    conditionType?: string,
    isActiveToRender: boolean,
}

export type MatrixCellStateType = {
    cellId: number,
    leftCellId?: number,
    rightCellId?: number,
    topCellId?: number,
    bottomCellId?: number,
    blockType?: string,
    childCells: Array<MatrixCellChildCellType>,
    parentCellBlockType?: string,
    isActiveToRender?: boolean,
    parentPosition?: string,
    flowNodeId?: number,
    conditionType?: string
}

export type MatrixCellRowStateType = Array<MatrixCellStateType>;

export type FlowMatrixStateType = {
    matrixSize: number,
    matrix: Array<MatrixCellRowStateType>
}

/* ------------------ Flow Errors --------------------- */
export type ErrorInBlockStateType = {
    cellId: number,
    messages: Array<string>
}

/* ------------------ Flow Templates --------------------- */
export type FlowTemplateStateType = {
    id: number,
    usageAmount: number,
    name: string,
    description: string,
    flowId: string,
    group: {
        name: string
    }
}

export type FlowTemplateResponseType = {
    id: number,
    usageAmount: number,
    name: string,
    description: string,
    flowId: string,
    groupId: string,
}

/* --------------------- Dimmer ------------------------ */
export type DimmerStateType = {
    [string]: boolean
}

/* ------------------ Common State Types --------------------- */

/* Dispatch */

export type GetState = () => Object;
export type PromiseAction = Promise<Object>;
export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any; //eslint-disable-line
export type Dispatch = (action: Object | ThunkAction | PromiseAction | Array<Object>) => any;

/* Modal */
export type ModalStateType = {
    isModalShown: boolean;
    modalProps?: Object,
    component?: Object,
};

/* Spinner */
export type SpinnerStateType = {
    active: boolean,
    text: ?string
};

/* Side bars */
export type AppSideBarsStateType = {
    FLOW_TEMPLATES_MAIN_SIDE_BAR: boolean,
    FLOW_TEMPLATES_MESSAGES_SIDE_BAR: boolean,
    FLOW_ERRORS_SIDE_BAR: boolean
};

/* User details */

export type UserCompany = {
    id: number;
    name: string;
    uiName: string;
    status: string;
    providerId: string;
    provider?: string;
    email: string;
    startDate: string;
    providerCompanyId: string;
    address: Object;
    fullAccess: boolean;
    logoUrl: string;
    organization?: {
        id: number;
    };
}

export type UserDetails = {
    partnerName?: string;
    orgId: number;
    userType: string;
    additionalSyncsAmount: number;
    name: string;
    grossProfit12LastMonths: number;
    syncLimits: string;
    paid: boolean;
    remainingSyncsAmount: number;
    totalSuccessfulSyncsAmount: number;
    subscriptions: string[];
    timeZone: string;
    freeTrial: boolean;
    freeSyncsAmount?: number;
    heardFrom?: string;
    heardFromOtherDescription?: string;
    accCompany?: UserCompany;
    ppCompanies: UserCompany[];
    ecommerceCompanies: UserCompany[];
    currency: string;
    freeReceivePaymentsAmount: number;
    users: string[];
    monthlyRevenueVolume: string;
    businessInfo: {
        howLongInBusiness: string;
        industries: string[];
    };
    bookkeeperEmail?: string;
    country: string;
    status: string;
    archived: boolean;
    notificationSettings: {
        frequency: string;
        insightFrequency: string;
        sendReconciliationReminder: boolean;
    };
    unresolvedRequestedPlatforms: string[];
    products: string[];

    // for onboarding
    mobile?: string;
    professionOther?: string;
    valid?: boolean;
    creditCardRequired?: boolean;
    totalInvoicingPaymentsAmount: number;
    totalPaymentLinksPaymentsAmount: number;

    marketplace?: string;

    // for launch page
    launchFlowStep: string | null;

    administrativeSettings?: {
        dailySummaryInitialSetupStatus?: string;
        tryDailySummaryCampaign?: boolean;
    };
};

/* Auth */
export type AuthDetails = {
    authenticated: ?boolean;
    csrfFlowlessTemplatesToken: ?string;
};

/* Provider data */ // TODO Review later - may be we can use one common type

export type ProviderDataEntityStateType = {
    id: number,
    displayName: string
};

export type TaxCodesStateType = {
    name: string;
    id: string;
    percent: number;
};

export type TaxCalculationTypes = {
    name: string;
    value: string;
};

export const BILLABLE = 'BILLABLE';
export const NOT_BILLABLE = 'NOT_BILLABLE';

export type BillableExpenseTracking = BILLABLE | NOT_BILLABLE;

export type ProviderCompanySettingsStateType = {
    id: number,
    accountingInfoPrefs: {
        trackDepartments: boolean,
        classTrackingPerTxn: boolean,
        classTrackingPerTxnLine: boolean,
    },
    salesFormsPrefs: {
        allowShipping: boolean,
    },
    vendorAndPurchasesPrefs: {
        billableExpenseTracking: BillableExpenseTracking, // if true shows Billable checkbox
        trackingByCustomer: boolean // if true show Customer selector
    },
    classTrackingEnabled: boolean
};

export const PROVIDER_INTUIT = 'INTUIT';
export const PROVIDER_SYNDER = 'SYNDER';
export const PROVIDER_INTUIT_DESKTOP = 'INTUIT_DESKTOP';

export type CurrentProviderStateType = PROVIDER_INTUIT | PROVIDER_SYNDER | PROVIDER_INTUIT_DESKTOP;

export type GlobalState = {
    contacts: ContactsStateType,
    products: ProductsStateType,
    events: {
        eventsList: Array<EventStateType>,
        selectedEvent: EventStateType
    },
    templateGroups: Array<TemplateGroupsStateType>,
    providerData: {
        accounts: Array<ProviderDataEntityStateType>,
        classes: Array<ProviderDataEntityStateType>,
        departments: Array<ProviderDataEntityStateType>,
        paymentMethods: Array<ProviderDataEntityStateType>,
        providerSettings: ProviderCompanySettingsStateType,
        terms: Array<ProviderDataEntityStateType>,
        taxCodes: Array<TaxCodesStateType>,
        taxCalculationTypes: Array<TaxCalculationTypes>,
    },

    flow: FlowTreeStateType,
    flowOperations: {
        activeCondition: BlockConditionStateType,
        activeAction: BlockActiveActionStateType,
        conditionFields: ConditionFieldsState
    },
    flowErrors: Array<ErrorInBlockStateType>,
    flowTemplates: {
        flowTemplatesList: Array<FlowTemplateStateType>,
        selectedTemplateGroup: string
    },

    activeMatrixCell: MatrixCellStateType,
    focusOnActiveCell: boolean,
    flowMatrix: FlowMatrixStateType,

    appDimmers: DimmerStateType,
    appSideBars: AppSideBarsStateType,
    leftMenuContent: string,
    modals: ModalStateType,
    showHints: boolean,
    spinner: SpinnerStateType,

    form: {
        providerActionEntityForm: Object
    },
    currentProvider: CurrentProviderStateType,
    // mailbuilderReducer: Object
}