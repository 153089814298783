// @flow

import {flowConditionsDispatchTypes, SET_INITIAL_STATE} from '../../constants/dispatch/dispatchTypes';
import type {ConditionFieldsState} from '../../constants/flowTyped/flowTypedStates';
import type {ConditionFieldsACType} from '../../constants/flowTyped/flowTypedACTypes';

const initialState = {};

const conditionsReducer = (state: ConditionFieldsState = initialState, action: ConditionFieldsACType): ConditionFieldsState => {
    switch (action.type) {
        case flowConditionsDispatchTypes.SET_FLOW_CONDITION_FIELDS:
            return {...state, ...action.conditionFields};
        case SET_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default conditionsReducer;
