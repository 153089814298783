// @flow

import {flowTemplatesDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {FlowTemplateStateType} from '../../constants/flowTyped/flowTypedStates';
import type {FlowTemplatesACTypes} from '../../constants/flowTyped/flowTypedACTypes';

export const setFlowTemplatesList = (flowTemplatesList: Array<FlowTemplateStateType>): FlowTemplatesACTypes => ({
    type: flowTemplatesDispatchTypes.SET_FLOW_TEMPLATES_LIST,
    flowTemplatesList
});

export const setSelectedTemplateGroup = (templatesGroup: ?string) => ({
    type: flowTemplatesDispatchTypes.SET_SELECTED_TEMPLATES_GROUP,
    templatesGroup
});