// @flow
import isEmpty from 'lodash/isEmpty';
import {apiRequest, getSessionStorageValue} from '../../api/api';
import {addFlowMatrixStartCell, changeActiveCell} from '../flowMatrix/actions';
import {setEvents, setFlowEventEntityAction} from './actionCreators';
import {addFirstFlowElement, updateFlowTreeEventId} from '../flowTree/actionCreators';
import {stopSpinner} from '../commonActions/commonAppActionCreators';
import {apiLoadSelectEventConditionFieldsList} from '../flowOperations/actions';
// eslint-disable-next-line import/no-cycle
import applicationStore from '../../applicationStore';
import {fullMatrixUpdate} from '../flowMatrix/actionCreators';
import {updateFlowAndMatrix} from '../../utils/flowTreeUtils';
import {getMatrixCellById} from '../../utils/flowMatrixUtils';
// eslint-disable-next-line import/no-cycle
import {showErrorTooltip} from '../commonActions/commonAppActions';
// eslint-disable-next-line import/no-cycle
import {apiLoadContactsInfo} from '../contacts/actions';

import {PAYMENT_REMINDER_EVENT_TYPE} from '../../constants/menu/leftMenuContentConstants';
import type {Dispatch, EventStateType} from '../../constants/flowTyped/flowTypedStates';

type Response = {
    errorWith?: string,
    events?: Array<EventStateType>
}

export const apiLoadEvents = () => async (dispatch: Dispatch) => {
    const res: Response = await apiRequest('/v1/event');
    if (res.events) {
        dispatch(setEvents(res.events.filter(event => event.visible)));
    } else {
        showErrorTooltip('Can\'t load server data. Please, try again later or contact our support-team');
    }
    return res;
};

/* Select Event entity action for flow tree and call next actions */
export const setEntityAction = (selectedEvent: EventStateType) => async (dispatch: Dispatch) => {
    const {flow, events} = applicationStore.getState();
    let shouldFinishSpinner = false;
    if (isEmpty(flow.block) && !events.selectedEvent.type) {
        shouldFinishSpinner = true;
        // Set data about contacts
        await apiLoadContactsInfo()(dispatch);
        // Load conditions for selected event
        await apiLoadSelectEventConditionFieldsList([selectedEvent.entity], [])(dispatch);
    }
    // Select Event entity action for flow tree
    dispatch(setFlowEventEntityAction(selectedEvent));

    if (selectedEvent.type === PAYMENT_REMINDER_EVENT_TYPE) {
        const {flowOperations: {conditionFields}} = applicationStore.getState();
        const dueDateField = conditionFields[selectedEvent.entity].find(field => field.name === 'DUE_DATE');
        const reminderRequiredPartOfFlow = {
            ...flow,
            companyId: flow.companyId || getSessionStorageValue('companyId'),
            eventId: selectedEvent.id,
            block: {
                blockType: 'Condition',
                position: 'RIGHT',
                statement: {
                    fieldsChain: {
                        fieldsPath: [dueDateField]
                    },
                }
            }
        };
        const {newFlow, updatedFlowMatrix, center} = updateFlowAndMatrix(reminderRequiredPartOfFlow);
        dispatch(addFirstFlowElement(newFlow));
        dispatch(fullMatrixUpdate(updatedFlowMatrix));
        if (center) {
            const newActiveCell = getMatrixCellById(center.rightCellId);
            if (newActiveCell) {
                changeActiveCell(newActiveCell)(dispatch);
            }
        }
    } else {
        // Add event id to flow tree
        dispatch(updateFlowTreeEventId(selectedEvent.id));
        // Set start flow position-cell
        addFlowMatrixStartCell()(dispatch);
    }
    if (shouldFinishSpinner) {
        dispatch(stopSpinner());
    }
};