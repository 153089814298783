// @flow

import {ProductsStateType} from '../../constants/flowTyped/flowTypedStates';
import {DEFAULT_COMMON_REDUCER_META} from '../../constants/common';
import {ProductsACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import {productsDispatchTypes} from '../../constants/dispatch/dispatchTypes';

const initialState = {
    list: [],
    meta: DEFAULT_COMMON_REDUCER_META
};

const productsReducer = (state: ProductsStateType = initialState, action: ProductsACTypes) => {
    if (action.type === productsDispatchTypes.SET_PRODUCTS_DATA) {
        const {meta, list} = action.payload;
        return {
            list: list || state.list,
            meta: {
                ...state.meta,
                ...meta,
            }
        };
    }
    if (action.type === productsDispatchTypes.UPDATE_PRODUCTS_DATA) {
        const {meta, list} = action.payload;
        return {
            list: [...state.list, ...(list || [])],
            meta: {
                ...state.meta,
                ...meta,
            }
        };
    }
    if (action.type === productsDispatchTypes.CLEAN_PRODUCTS_DATA) {
        return initialState;
    }
    return state;
};

export default productsReducer;