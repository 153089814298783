// @flow

import QBOItemLineDetail from './QBOItemLineDetail';

class QBOItemBasedSalesLineDetail extends QBOItemLineDetail {
    taxCodeRef: Object;

    taxCodeRef = {
        value: {
            name: 'value',
            label: 'Tax',
            type: 'string',
            placeholder: 'Tax code'
        }
    };

    getFields() {
        return {
            ...super.getFields(),
            taxCodeRef: this.taxCodeRef
        };
    }
}

export default QBOItemBasedSalesLineDetail;