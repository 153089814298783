// @flow
import {eventsDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {EventsListACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import type {EventStateType} from '../../constants/flowTyped/flowTypedStates';

const initialState = [];

const eventsListReducer = (state: Array<EventStateType> = initialState, action: EventsListACTypes): Array<EventStateType> => {
    if (action.type === eventsDispatchTypes.SET_EVENTS_DATA) {
        return action.events;
    }
    return state;
};

export default eventsListReducer;