// @flow
import React, {Component} from 'react';
import autobind from 'react-autobind';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import camelCase from 'lodash/camelCase';
import {Icon} from 'semantic-ui-react';
import {setTextAndCursor} from '../../actions/flowOperations/actionCreators';

import {convertTextFromEmailEditor, parsingTextForCursor} from '../../utils/convertUtils';
import {copyTextToClipboard} from '../../utils/commonUtils';

import type {
    BlockActiveActionStateType,
    ConditionFieldStateType,
    Dispatch,
    GlobalState
} from '../../constants/flowTyped/flowTypedStates';

type Props = {
    entityText: string,
    field: ConditionFieldStateType,
    actions: {
        setTextAndCursor: Function,
    },
    activeAction: BlockActiveActionStateType,
    isFullRender: ?boolean
}

const FIELDS_ADDITIONAL_SYMBOLS_LENGTH = 6;

class SimpleAccordionFieldComponent extends Component<Props> {
    constructor(props) {
        super(props);
        autobind(this);
        this.state = {
            placeholderCopied: false
        };
    }

    onLeaveCopy() {
        this.setState(state => ({...state, placeholderCopied: false}));
    }

    onCopyClick() {
        const {entityText} = this.props;
        this.setState(state => ({...state, placeholderCopied: true}));
        copyTextToClipboard(`{{${entityText}}}`);
    }

    clickOnSimpleField() {
        const {actions: {setTextAndCursor}, entityText, field, activeAction: {message: {text}, cursorPosition, positionCursorOfRow}, isFullRender} = this.props;
        if (!isFullRender) {
            let newTextForActiveAction;
            if (text) {
                if (document.getElementsByClassName('email-modal-dropdown')[0]) {
                    newTextForActiveAction = convertTextFromEmailEditor(text, positionCursorOfRow, entityText, camelCase(field.uiName), cursorPosition);
                } else {
                    newTextForActiveAction = parsingTextForCursor(text, entityText, cursorPosition);
                }
            } else {
                newTextForActiveAction = parsingTextForCursor(null, entityText, cursorPosition);
            }
            const newCursorPosition = cursorPosition + entityText.length + FIELDS_ADDITIONAL_SYMBOLS_LENGTH;
            setTextAndCursor(newTextForActiveAction, newCursorPosition, positionCursorOfRow);
        }
    }

    render() {
        const {field, isFullRender} = this.props;
        const {placeholderCopied} = this.state;
        const uiName = camelCase(field.uiName);

        return (
            <div className={`simple-accordion-field ${isFullRender ? 'simple-accordion-field-custom' : ''}`}
                 onClick={this.clickOnSimpleField}
            >
                {isFullRender ? (
                    <li>
                        <span>{uiName}</span>
                        <span className={`copy-icon-tooltip ${placeholderCopied ? 'copy-icon-copied-tooltip' : ''}`}
                              onMouseLeave={this.onLeaveCopy}
                        >
                            <Icon name="copy"
                                  onClick={this.onCopyClick}
                                  className="copy outline copy-icon-custom"
                            />
                        </span>
                    </li>
                ) : (
                    <li><span>{uiName}</span></li>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: GlobalState): Object => ({
    activeAction: state.flowOperations.activeAction
});

const mapDispatchToProps = (dispatch: Dispatch) => (
    {
        actions: bindActionCreators({
            setTextAndCursor
        }, dispatch)
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(SimpleAccordionFieldComponent);