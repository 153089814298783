import QBOAccountLineDetail from '../lineItems/QBOAccountLineDetail';
import {BillableExpenseTracking} from '../../../../flowTyped/flowTypedStates';

class QBOCashExpenseAccountLine extends QBOAccountLineDetail {
    billableStatus: BillableExpenseTracking;
    description: Object;
    customerRef: Object;

    billableStatus = {
        name: 'billableStatus',
        label: 'Billable',
        type: 'string'
    };

    customerRef = {
        value: {
            name: 'value',
            label: 'Customer',
            type: 'string',
            placeholder: 'Current customer'
        }
    };

    description = {
        name: 'description',
        label: 'Description',
        type: 'string'
    }

    getFields() {
        return {
            ...super.getFields(),
            billableStatus: this.billableStatus,
            description: this.description,
            customerRef: this.customerRef
        };
    }
}

export default QBOCashExpenseAccountLine;