// @flow

import {flowTemplatesDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {FlowTemplatesACTypes} from '../../constants/flowTyped/flowTypedACTypes';

const initialState = '';

export const SELECTED_POPULAR_TEMPLATE_GROUP = 'Most popular';

const selectedTemplateGroupReducer = (state: string = initialState, action: FlowTemplatesACTypes): string => {
    if (action.type === flowTemplatesDispatchTypes.SET_SELECTED_TEMPLATES_GROUP) {
        return action.templatesGroup;
    }
    return state;
};

export default selectedTemplateGroupReducer;