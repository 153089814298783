import type {CurrentProviderACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import {currentProviderDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import {PROVIDER_INTUIT} from '../../constants/flowTyped/flowTypedStates';
import type {CurrentProviderStateType} from '../../constants/flowTyped/flowTypedStates';

function currentProvider(state: CurrentProviderStateType = PROVIDER_INTUIT, action: CurrentProviderACTypes): CurrentProviderStateType {
    if (action.type === currentProviderDispatchTypes.SET_PROVIDER_TYPE) {
        return action.provider;
    }
    return state;
}

export default currentProvider;