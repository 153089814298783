// @flow

import QBOBasicContact from './QBOBasicContact';

class QBOCustomer extends QBOBasicContact {
    getFields() {
        const parentFields = super.getFields();
        const {address: billAddr, ...restFields} = parentFields;
        return {
            ...restFields,
            billAddr
        };
    }
}

export default QBOCustomer;