import {flowDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {FlowBlockStateType, FlowTreeStateType} from '../../constants/flowTyped/flowTypedStates';
import type {FlowTreeACTypes} from '../../constants/flowTyped/flowTypedACTypes';

export const updateFlowName = (name: string): FlowTreeACTypes => ({
    type: flowDispatchTypes.SET_FLOW_NAME,
    name
});

export const updateFlowTreeEventId = (eventId: number): FlowTreeACTypes => ({
    type: flowDispatchTypes.SET_FLOW_EVENT_ID,
    eventId
});

export const addFirstFlowElement = (newFlow: FlowTreeStateType, customBlockCount?: Boolean): FlowTreeACTypes => ({
    type: flowDispatchTypes.SET_FIRST_ELEMENT,
    newFlow,
    customBlockCount
});

export const updateFlowBlock = (newFlowBlock: FlowBlockStateType, customBlockCount?: Boolean): FlowTreeACTypes => ({
    type: flowDispatchTypes.SET_FLOW_BLOCK,
    newFlowBlock,
    customBlockCount
});

export const increaseBlockCount = () => ({
    type: flowDispatchTypes.SET_FLOW_BLOCK_COUNT_INCREASE
});

export const decreaseBlockCount = () => ({
    type: flowDispatchTypes.SET_FLOW_BLOCK_COUNT_DECREASE
});

export const setFlowId = (id): FlowTreeACTypes => ({
    type: flowDispatchTypes.SET_FLOW_ID,
    id
});