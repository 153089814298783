// @flow

import {providerDataDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {ProviderDataEntityStateType} from '../../constants/flowTyped/flowTypedStates';
import type {ProviderDataACTypes} from '../../constants/flowTyped/flowTypedACTypes';

const initialState = [];

export default function (state: Array<ProviderDataEntityStateType> = initialState, action: ProviderDataACTypes) {
    if (action.type === providerDataDispatchTypes.SET_PROVIDER_DATA_PAYMENT_METHODS) {
        return action.entitiesList;
    }
    if (action.type === providerDataDispatchTypes.CLEAN_PROVIDER_DATA) {
        return initialState;
    }
    return state;
}