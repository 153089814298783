// @flow

import QBOPurchaseByVendor from './QBOPurchaseByVendor';

class QBOPurchase extends QBOPurchaseByVendor {
    entityRef: Object;

    entityRef = {
        value: {
            name: 'value',
            label: 'Payee',
            placeholder: 'Current Payee',
            type: 'string'
        }
    };

    getFields() {
        const {vendorRef, ...restParentFields} = super.getFields();
        return {
            ...restParentFields,
            entityRef: this.entityRef,
        };
    }
}

export default QBOPurchase;