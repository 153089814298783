// @flow

/* App dispatch action types */

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

/* Contacts */
const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
const UPDATE_CUSTOMER_DATA = 'UPDATE_CUSTOMER_DATA';
const SET_EMPLOYEE_DATA = 'SET_EMPLOYEE_DATA';
const UPDATE_EMPLOYEE_DATA = 'UPDATE_EMPLOYEE_DATA';
const SET_VENDOR_DATA = 'SET_VENDOR_DATA';
const UPDATE_VENDOR_DATA = 'UPDATE_VENDOR_DATA';
export const contactsDispatchTypes = {
    SET_CUSTOMER_DATA,
    UPDATE_CUSTOMER_DATA,
    SET_EMPLOYEE_DATA,
    UPDATE_EMPLOYEE_DATA,
    SET_VENDOR_DATA,
    UPDATE_VENDOR_DATA
};

/* Products */
const SET_PRODUCTS_DATA = 'SET_PRODUCTS_DATA';
const CLEAN_PRODUCTS_DATA = 'CLEAN_PRODUCTS_DATA';
const UPDATE_PRODUCTS_DATA = 'UPDATE_PRODUCTS_DATA';
export const productsDispatchTypes = {
    SET_PRODUCTS_DATA,
    CLEAN_PRODUCTS_DATA,
    UPDATE_PRODUCTS_DATA
};

/* Events */
const SET_EVENTS_DATA = 'SET_EVENTS_DATA';
const SET_SELECTED_EVENT_ENTITY = 'SET_SELECTED_EVENT_ENTITY';
const SET_SELECTED_EVENT_ACTION_TYPE = 'SET_SELECTED_EVENT_ACTION_TYPE';
export const eventsDispatchTypes = {
    SET_EVENTS_DATA,
    SET_SELECTED_EVENT_ENTITY,
    SET_SELECTED_EVENT_ACTION_TYPE
};

/* Rules form */
export const SET_DYNAMIC_RULES_FORM = 'SET_DYNAMIC_RULES_FORM';

/* Groups */
export const SET_TEMPLATE_GROUPS_DATA = 'SET_TEMPLATE_GROUPS_DATA';

/* Flow conditions */
const SET_FLOW_CONDITION_FIELDS = 'SET_FLOW_CONDITION_FIELDS';
const SET_FLOW_CONDITION_OPERAND = 'SET_FLOW_CONDITION_OPERAND';
const SET_FLOW_CONDITION_VALUE = 'SET_FLOW_CONDITION_VALUE';
const SET_FLOW_CONDITION_DATA = 'SET_FLOW_CONDITION_DATA';
const SET_FLOW_CONDITION_PATH = 'SET_FLOW_CONDITION_PATH';
export const flowConditionsDispatchTypes = {
    SET_FLOW_CONDITION_FIELDS,
    SET_FLOW_CONDITION_OPERAND,
    SET_FLOW_CONDITION_VALUE,
    SET_FLOW_CONDITION_DATA,
    SET_FLOW_CONDITION_PATH
};

/* Flow actions */
const SET_FLOW_ACTION_TYPE = 'SET_FLOW_ACTION_TYPE';
const SET_FLOW_ACTION_RECIPIENT = 'SET_FLOW_ACTION_RECIPIENT';
const SET_FLOW_ACTION_RECIPIENT_CONTACT_INFO = 'SET_FLOW_ACTION_RECIPIENT_CONTACT_INFO';
const SET_FULL_FLOW_ACTION_CONTACT = 'SET_FULL_FLOW_ACTION_CONTACT';
const SET_FLOW_ACTION_SUBJECT = 'SET_FLOW_ACTION_SUBJECT';
const SET_FLOW_ACTION_TEXT = 'SET_FLOW_ACTION_TEXT';
const SET_FLOW_ACTION_DATA = 'SET_FLOW_ACTION_DATA';
const SET_ATTACH_ENTITY = 'SET_ATTACH_ENTITY';
const SET_CURSOR_POSITION = 'SET_CURSOR_POSITION';
const SET_TEXT_AND_CURSOR_POSITION = 'SET_TEXT_AND_CURSOR_POSITION';
const SET_PROVIDER_ACTION_ENTITY = 'SET_PROVIDER_ACTION_ENTITY';
const SET_PROVIDER_ACTION_ENTITY_ACTION = 'SET_PROVIDER_ACTION_ENTITY_ACTION';
export const flowActionsDispatchTypes = {
    SET_FLOW_ACTION_TYPE,
    SET_FLOW_ACTION_RECIPIENT,
    SET_FLOW_ACTION_RECIPIENT_CONTACT_INFO,
    SET_FULL_FLOW_ACTION_CONTACT,
    SET_FLOW_ACTION_SUBJECT,
    SET_FLOW_ACTION_TEXT,
    SET_FLOW_ACTION_DATA,
    SET_ATTACH_ENTITY,
    SET_CURSOR_POSITION,
    SET_TEXT_AND_CURSOR_POSITION,
    SET_PROVIDER_ACTION_ENTITY,
    SET_PROVIDER_ACTION_ENTITY_ACTION,
};

/* Flow Matrix */
const SET_FLOW_MATRIX = 'SET_FLOW_MATRIX';
const UPDATE_FLOW_MATRIX_CELLS = 'UPDATE_FLOW_MATRIX_CELLS';
const SET_ACTIVE_MATRIX_CELL = 'SET_ACTIVE_MATRIX_CELL';
const REFRESH_ACTIVE_CELL = 'REFRESH_ACTIVE_CELL';
export const flowMatrixDispatchTypes = {
    SET_FLOW_MATRIX,
    UPDATE_FLOW_MATRIX_CELLS,
    SET_ACTIVE_MATRIX_CELL,
    REFRESH_ACTIVE_CELL
};

/* Flow Templates */
const SET_FLOW_TEMPLATES_LIST = 'SET_FLOW_TEMPLATES_LIST';
const SET_SELECTED_TEMPLATES_GROUP = 'SET_SELECTED_TEMPLATES_GROUP';
export const flowTemplatesDispatchTypes = {
    SET_FLOW_TEMPLATES_LIST,
    SET_SELECTED_TEMPLATES_GROUP
};

/* Flow */

const SET_FLOW_NAME = 'SET_FLOW_NAME';
const SET_FLOW_EVENT_ID = 'SET_FLOW_EVENT_ID';
const SET_FIRST_ELEMENT = 'SET_FIRST_ELEMENT';
const SET_FLOW_BLOCK = 'SET_FLOW_BLOCK';
const SET_FLOW_ID = 'SET_FLOW_ID';
const SET_FLOW_BLOCK_COUNT_INCREASE = 'SET_FLOW_BLOCK_COUNT_INCREASE';
const SET_FLOW_BLOCK_COUNT_DECREASE = 'SET_FLOW_BLOCK_COUNT_DECREASE';
export const flowDispatchTypes = {
    SET_FLOW_NAME,
    SET_FLOW_EVENT_ID,
    SET_FIRST_ELEMENT,
    SET_FLOW_BLOCK,
    SET_FLOW_ID,
    SET_FLOW_BLOCK_COUNT_INCREASE,
    SET_FLOW_BLOCK_COUNT_DECREASE
};

/* Left menu active content */
const SET_LEFT_MENU_TYPE = 'SET_LEFT_MENU_TYPE';
export const leftMenuContentDispatchTypes = {
    SET_LEFT_MENU_TYPE,
};

/* Provider data */

const SET_PROVIDER_COMPANY_SETTINGS = 'SET_PROVIDER_COMPANY_SETTINGS';
const SET_PROVIDER_DATA_ACCOUNTS = 'SET_PROVIDER_DATA_ACCOUNTS';
const SET_PROVIDER_DATA_CLASSES = 'SET_PROVIDER_DATA_CLASSES';
const SET_PROVIDER_DATA_DEPARTMENTS = 'SET_PROVIDER_DATA_DEPARTMENTS';
const SET_PROVIDER_DATA_PAYMENT_METHODS = 'SET_PROVIDER_DATA_PAYMENT_METHODS';
const SET_PROVIDER_DATA_TERMS = 'SET_PROVIDER_DATA_TERMS';
const SET_PROVIDER_DATA_TAX_CODES = 'SET_PROVIDER_DATA_TAX_CODES';
const SET_PROVIDER_DATA_TAX_CALCULATION_TYPES = 'SET_PROVIDER_DATA_TAX_CALCULATION_TYPES';
const CLEAN_PROVIDER_DATA = 'CLEAN_PROVIDER_DATA';
export const providerDataDispatchTypes = {
    SET_PROVIDER_COMPANY_SETTINGS,
    SET_PROVIDER_DATA_ACCOUNTS,
    SET_PROVIDER_DATA_CLASSES,
    SET_PROVIDER_DATA_DEPARTMENTS,
    SET_PROVIDER_DATA_PAYMENT_METHODS,
    SET_PROVIDER_DATA_TERMS,
    SET_PROVIDER_DATA_TAX_CODES,
    SET_PROVIDER_DATA_TAX_CALCULATION_TYPES,
    CLEAN_PROVIDER_DATA
};

/* Spinner */
const RUN_SPINNER = 'RUN_SPINNER';
const STOP_SPINNER = 'STOP_SPINNER';
export const spinnerTypes = {
    RUN_SPINNER,
    STOP_SPINNER
};

// Common SideBars reducer
const SET_IS_SIDEBAR_SHOWN = 'SET_IS_SIDEBAR_SHOWN';
export const appSideBarsDispatchTypes = {
    SET_IS_SIDEBAR_SHOWN
};

// Current provider
const SET_PROVIDER_TYPE = 'SET_PROVIDER_TYPE';
export const currentProviderDispatchTypes = {
    SET_PROVIDER_TYPE
};

// TODO If separate sidebar will be used for flow templates -> move it to this reducer and dispatch types/actions and else

/* Api's errors */
const SAVE_FLOW_ERRORS = 'SAVE_FLOW_ERRORS';
const UPDATE_FLOW_ERRORS_MESSAGES = 'UPDATE_FLOW_ERRORS_MESSAGES';
const DELETE_ERRORS_BLOCK = 'DELETE_ERRORS_BLOCK';
export const flowErrorsDispatchTypes = {
    SAVE_FLOW_ERRORS,
    UPDATE_FLOW_ERRORS_MESSAGES,
    DELETE_ERRORS_BLOCK
};
/* Modals */
const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';
export const modalsDispatchTypes = {
    SHOW_MODAL,
    HIDE_MODAL
};

const SHOW_APP_DIMMER = 'SHOW_APP_DIMMER';
const HIDE_APP_DIMMER = 'HIDE_APP_DIMMER';
export const dimmersDispatchTypes = {
    SHOW_APP_DIMMER,
    HIDE_APP_DIMMER
};

/* Newbie */
const SET_NEWBIE = 'SET_NEWBIE';
export const newbieTypes = {
    SET_NEWBIE
};

const SET_FOCUS_ON_ACTIVE_CELL = 'SET_FOCUS_ON_ACTIVE_CELL';
export const leftFocusDispatchTypes = {
    SET_FOCUS_ON_ACTIVE_CELL
};

/* User details */
const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const UserDetailsDispatchTypes = {
    SET_USER_DETAILS
};

/* Auth */
const SET_AUTH_DETAILS = 'SET_AUTH_DETAILS';
export const AuthDispatchTypes = {
    SET_AUTH_DETAILS
};

/* Transaction placeholders modal */
const SET_TRANSACTION_PLACEHOLDERS_MODAL = 'SET_TRANSACTION_PLACEHOLDERS_MODAL';
export const transactionPlaceholdersModalDispatchTypes = {
    SET_TRANSACTION_PLACEHOLDERS_MODAL
};