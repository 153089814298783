// @flow

import QBOBasicTransaction from '../QBOBasicTransaction';
import QBOAccountLineDetail from '../lineItems/QBOAccountLineDetail';

class QBOPurchaseByVendor extends QBOBasicTransaction {
    vendorRef: Object;
    apAccountRef: Object;
    accountLine: Object;

    vendorRef = {
        value: {
            name: 'value',
            label: 'Vendor',
            placeholder: 'Select vendor',
            type: 'string',
            isRequired: true
        }
    };

    apAccountRef = {
        value: {
            name: 'value',
            label: 'Account',
            placeholder: 'Select account',
            type: 'string',
            isRequired: false
        }
    };

    accountLine = {
        ...new QBOAccountLineDetail().getFields(),
        accountLineName: 'accountBasedExpenseLineDetail'
    };

    getFields() {
        return {
            ...super.getFields(),
            vendorRef: this.vendorRef,
            apAccountRef: this.apAccountRef,
            accountLine: this.accountLine,
        };
    }
}

export default QBOPurchaseByVendor;