// @flow

import {flowMatrixDispatchTypes, SET_INITIAL_STATE} from '../../constants/dispatch/dispatchTypes';
// eslint-disable-next-line import/no-cycle
import {generateNewMatrix} from '../../utils/flowMatrixUtils';

import type {FlowMatrixStateType} from '../../constants/flowTyped/flowTypedStates';
import type {FlowMatrixACTypes} from '../../constants/flowTyped/flowTypedACTypes';

const getInitialState = () => ({
    matrixSize: 5,
    matrix: generateNewMatrix()
});

function flowMatrix(state: FlowMatrixStateType, action: FlowMatrixACTypes) {
    switch (action.type) {
        case flowMatrixDispatchTypes.SET_FLOW_MATRIX:
            return action.newMatrix;
        case flowMatrixDispatchTypes.UPDATE_FLOW_MATRIX_CELLS: {
            return {...state, matrix: action.newMatrixGrid};
        }
        case SET_INITIAL_STATE:
            return getInitialState();
        default:
            return state || getInitialState();
    }
}

export default flowMatrix;
