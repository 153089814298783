const getEnvVariable = () => {
    if (window.urls.WEB === '{{WEB}}' || window.urls.HOME === '{{HOME}}' || window.urls.RULES_NODE_API === '{{RULES_NODE_API}}' ||
    window.urls.API === '{{API}}') {
        throw new Error('Please add data to .env file or pass it into container properly');
    }

    return window.urls;
};

export default getEnvVariable;
