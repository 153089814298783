// @flow
import {flowErrorsDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {ErrorInBlockStateType} from '../../constants/flowTyped/flowTypedStates';
import type {FlowErrorsACType} from '../../constants/flowTyped/flowTypedACTypes';

export const saveFlowErrors = (errors: Array<ErrorInBlockStateType>): FlowErrorsACType => ({
    type: flowErrorsDispatchTypes.SAVE_FLOW_ERRORS,
    errors
});

export const updateFlowErrors = (fixedErrors: Array<string>, cellId: number): FlowErrorsACType => ({
    type: flowErrorsDispatchTypes.UPDATE_FLOW_ERRORS_MESSAGES,
    fixedErrors,
    cellId
});

export const deleteErrorsBlock = (cellId: number): FlowErrorsACType => ({
    type: flowErrorsDispatchTypes.DELETE_ERRORS_BLOCK,
    cellId
});