// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux';
import autobind from 'react-autobind';
import camelCase from 'lodash/camelCase';

// eslint-disable-next-line import/no-cycle
import {checkFieldLikeAccordion, getDataForCheckByEntities} from '../../utils/fieldsAccordionUtils';

import type {
    ConditionFieldsState,
    ConditionFieldStateType,
    GlobalState
} from '../../constants/flowTyped/flowTypedStates';

type Props = {
    parent: string,
    field: ConditionFieldStateType,
    conditionFields: ConditionFieldsState,
    isFullRender: ?boolean
};

type State = {
    clicked: boolean
};

class CombineAccordionFieldComponent extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false
        };
        autobind(this);
    }

    getVisiblePart() {
        const {parent, field, conditionFields, isFullRender} = this.props;
        const dataForCheck = getDataForCheckByEntities(field, conditionFields);
        return checkFieldLikeAccordion(dataForCheck, parent, isFullRender);
    }

    clickOnCombineField() {
        this.setState(prevState => ({
            clicked: !prevState.clicked
        }));
    }

    render() {
        const {field, isFullRender} = this.props;
        const {clicked} = this.state;
        return (
            <div className={`combine-accordion-field ${clicked ? 'active' : 'false'} ${isFullRender ? 'combine-accordion-field-custom' : ''}`}>
                <div onClick={this.clickOnCombineField}>
                    <span>{camelCase(field.uiName)}</span>
                </div>
                {clicked && this.getVisiblePart()}
            </div>
        );
    }
}

const mapStateToProps = (state: GlobalState): Object => ({
    conditionFields: state.flowOperations.conditionFields
});

export default connect(mapStateToProps)(CombineAccordionFieldComponent);