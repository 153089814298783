// @flow
import React, {Component} from 'react';
import fromPairs from 'lodash/fromPairs';

import Dimmer from 'semantic-ui-react/dist/es/modules/Dimmer/Dimmer';
import Loader from 'semantic-ui-react/dist/es/elements/Loader/Loader';

import getEnvVariable from '../utils/getEnvVariable';

const URLS = getEnvVariable();
const {WEB} = URLS;

type Props = {
    history: Object,
    match: {
        params: {
            credentials: string
        }
    }
}

class AppInitializer extends Component<Props> {
    componentDidMount() {
        this.loadCredentials();
    }

    // TODO Review it and move varaibles to constants if possible
    async setAppCredentials(token: string, companyId: string, action: string, flowId?: string, templateId?: string) {
        const {history} = this.props;
        sessionStorage.setItem('flow_acc_tok', token);
        sessionStorage.setItem('companyId', companyId);
        if (templateId) {
            document.title = 'Create Rule';
            history.replace(`/template/${templateId}`);
        } else if (flowId && action === 'editFlow') {
            sessionStorage.setItem('flowId', flowId);
            document.title = 'Edit Rule';
            history.replace(`/editFlow/${flowId}`);
        } else if (action === 'createFlow') {
            sessionStorage.removeItem('flowId');
            document.title = 'Create Rule';
            history.replace('/createFlow');
        }
    }

    async loadCredentials() {
        const {credentials} = this.props.match.params;
        const {history} = this.props;
        const {action, companyId, flowId, token, templateId} = fromPairs(
            credentials.split('&')
            // flow-disable-next-line
                .map(parameter => parameter.split('='))
        );
        if (!token || !companyId) {
            history.push('/end');
        }
        const tokenResponse = await fetch(`${WEB}/apiAuth/exchangeJwt?exchangeToken=${token}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({exchangeToken: token})
        })
            .then(response => {
                if (response.status === 200) {
                    return Promise.resolve(response.json());
                }
                return Promise.reject();
            })
            .catch(() => Promise.resolve({errorWith: 'tokenError'}));
        if (tokenResponse && tokenResponse.accessToken) {
            this.setAppCredentials(tokenResponse.accessToken, companyId, action, flowId, templateId);
        } else {
            history.push('/end');
        }
    }

    render() {
        return (
            <Dimmer page active>
                <Loader size="huge" content="Preparing data..."/>
            </Dimmer>
        );
    }
}

export default AppInitializer;