// @flow
import type {
    BlockActiveActionStateType,
    BlockConditionStateType,
    ConditionFieldStateType,
    ConditionFieldsState,
    CompletedMatrixCellStateType,
    EventStateType,
    FlowBlockStateType,
    FlowTemplateStateType,
    FlowTreeStateType,
    MatrixCellRowStateType,
    FlowMatrixStateType,
    ErrorInBlockStateType,
    ProviderCompanySettingsStateType,
    ProviderDataEntityStateType,
    RecipientType, AuthDetails, TemplateGroupsStateType, TaxCodesStateType, TaxCalculationTypes, UserDetails
} from './flowTypedStates';
import {ContactStateType, ProductsStateType} from './flowTypedStates';
import type {RulesForm} from '../dynamicForm/dynamicFormTypes';

/* ------------------- Common AC types -------------------------------- */

// Modal
export type ModalACTypes =
    { type: 'SHOW_MODAL', payload: { component: Object, modalProps?: Object } }
    | { type: 'HIDE_MODAL' }
    | { type: 'SET_INITIAL_STATE' };

// Spinner
export type SpinnerACTypes =
    { type: 'RUN_SPINNER', payload: string }
    | { type: 'STOP_SPINNER' };

// Side bars
export type AppSideBarsACTypes =
    { type: 'SET_IS_SIDEBAR_SHOWN', changedSideBars: Object }

// current provider
export type CurrentProviderACTypes =
    { type: 'SET_PROVIDER_TYPE', provider: string};

/* ------------------- API Entities AC types ------------------------------ */

// Contacts
export type ContactsACTypes =
    { type: 'SET_VENDOR_DATA', payload: ContactStateType }
    | { type: 'SET_EMPLOYEE_DATA', payload: ContactStateType }
    | { type: 'SET_CUSTOMER_DATA', payload: ContactStateType }
    | {type: 'UPDATE_CUSTOMER_DATA', payload: ContactStateType }
    | {type: 'UPDATE_EMPLOYEE_DATA', payload: ContactStateType }
    | {type: 'UPDATE_VENDOR_DATA', payload: ContactStateType };

// Products
export type ProductsACTypes =
  { type: 'SET_PRODUCTS_DATA', payload: ProductsStateType }
  | { type: 'CLEAN_PRODUCTS_DATA'};

// Events list
export type EventsListACTypes = { type: 'SET_EVENTS_DATA', events: Array<EventStateType> }

// Current rule form list
export type DynamicRuleFormListACTypes = { type: 'SET_CURRENT_RULE_FORM', dynamicRuleForm: RulesForm }

// Groups list
export type TemplateGroupsListACTypes = { type: 'SET_GROUPS_DATA', templateGroups: Array<TemplateGroupsStateType> }

/* Selected event */
export type SelectedEventACTypes =
    | { type: 'SET_SELECTED_EVENT_ENTITY', entity: string }
    | { type: 'SET_SELECTED_EVENT_ACTION_TYPE', selectedEvent: EventStateType }
    | { type: 'SET_INITIAL_STATE' };

/* ------------------- Flow Operations AC types ------------------------------ */

export type ActiveConditionACType =
    { type: 'SET_FLOW_CONDITION_PATH', fieldsPath: Array<ConditionFieldStateType> }
    | { type: 'SET_FLOW_CONDITION_OPERAND', payload: { operand: string, shouldResetValue: boolean } }
    | { type: 'SET_FLOW_CONDITION_VALUE', value: Array<'Object' | 'string' | 'number' | null | typeof undefined> }
    | { type: 'SET_FLOW_CONDITION_DATA', newConditionState: ?BlockConditionStateType }
    | { type: 'SET_INITIAL_STATE' };

export type ActiveActionACType =
    { type: 'SET_FLOW_ACTION_TYPE', actionType: string }
    | { type: 'SET_FLOW_ACTION_RECIPIENT', recipientType: string }
    | { type: 'SET_FLOW_ACTION_RECIPIENT_CONTACT_INFO', contact: string }
    | { type: 'SET_FULL_FLOW_ACTION_CONTACT', recipient: RecipientType }
    | { type: 'SET_FLOW_ACTION_SUBJECT', subject: string }
    | { type: 'SET_FLOW_ACTION_TEXT', text: string }
    | { type: 'SET_FLOW_ACTION_DATA', newActionState: ?BlockActiveActionStateType }
    | { type: 'SET_ATTACH_ENTITY', attach: boolean }
    | { type: 'SET_CURSOR_POSITION', payload: { cursorPosition: number, positionCursorOfRow: number } }
    | { type: 'SET_TEXT_AND_CURSOR_POSITION', payload: { text: string, cursorPosition: number, positionCursorOfRow: number } }
    | { type: 'SET_PROVIDER_ACTION_ENTITY', entity: string }
    | { type: 'SET_PROVIDER_ACTION_ENTITY_ACTION', entityAction: string }
    | { type: 'SET_INITIAL_STATE' };

export type ConditionFieldsACType =
    { type: 'SET_FLOW_CONDITION_FIELDS', conditionFields: ConditionFieldsState }
    | { type: 'SET_INITIAL_STATE' }

/* ------------------- Flow tree AC types ------------------------------ */
export type FlowTreeACTypes =
    { type: 'SET_FLOW_NAME', name: string }
    | { type: 'SET_FLOW_EVENT_ID', eventId: number }
    | { type: 'SET_FIRST_ELEMENT', newFlow: FlowTreeStateType, customBlockCount:? Boolean }
    | { type: 'SET_FLOW_BLOCK', newFlowBlock: FlowBlockStateType, isRemovingBlock: Boolean, customBlockCount:? Boolean }
    | { type: 'SET_INITIAL_STATE' }
    | { type: 'SET_FLOW_ID', id: string}

export type FlowTemplatesACTypes =
    { type: 'SET_FLOW_TEMPLATES_LIST', flowTemplatesList: Array<FlowTemplateStateType> }
    | { type: 'SET_SELECTED_TEMPLATES_GROUP', templatesGroup: string }

/* ------------------- Provider data AC types ------------------------------ */
export type ProviderDataACTypes =
    {
        type: | 'SET_PROVIDER_DATA_ACCOUNTS'
            | 'SET_PROVIDER_DATA_CLASSES'
            | 'SET_PROVIDER_DATA_DEPARTMENTS'
            | 'SET_PROVIDER_DATA_PAYMENT_METHODS'
            | 'SET_PROVIDER_DATA_TERMS',
        entitiesList: Array<ProviderDataEntityStateType>
    }
    | { type: 'SET_PROVIDER_DATA_TAX_CODES', taxCodes: TaxCodesStateType }
    | { type: 'SET_PROVIDER_DATA_TAX_CALCULATION_TYPES', taxCalculationTypes: TaxCalculationTypes }
    | { type: 'SET_PROVIDER_COMPANY_SETTINGS', settings: ProviderCompanySettingsStateType }
    | { type: 'CLEAN_PROVIDER_DATA' }

/* ------------------- Provider data AC types ------------------------------ */
//
//
//
//
//
// TODO review it later
/* Type of actions for change matrix */
export type FlowMatrixACTypes =
    { type: 'SET_FLOW_MATRIX', newMatrix: FlowMatrixStateType }
    | { type: 'UPDATE_FLOW_MATRIX_CELLS', newMatrixGrid: Array<MatrixCellRowStateType> }
    | { type: 'SET_INITIAL_STATE' };

/* Type of actions for change active matrix cell */
export type ActiveMatrixCellACTypes =
    { type: 'SET_ACTIVE_MATRIX_CELL', activeCell: CompletedMatrixCellStateType }
    | { type: 'REFRESH_ACTIVE_CELL' }
    | { type: 'SET_INITIAL_STATE' };

/* Type of left menu */
export type LeftMenuACTypes = { type: 'SET_LEFT_MENU_TYPE', menuType: ?string };

/* Initial state type */
export type SetInitialStateACTypes = { type: 'SET_INITIAL_STATE' };

/* Types of content type */
export type ContentACType =
    { type: 'SET_LEFT_MENU_TYPE', menuType: string }
    | { type: 'SET_INITIAL_STATE' };

/* Flow errors action types */
export type FlowErrorsACType =
    { type: 'SAVE_FLOW_ERRORS', errors: Array<ErrorInBlockStateType> }
    | { type: 'UPDATE_FLOW_ERRORS_MESSAGES', cellId: number, fixedErrors: Array<string> }
    | { type: 'DELETE_ERRORS_BLOCK', cellId: number }
    | { type: 'SET_INITIAL_STATE' };

/* Dimmer types */
export type DimmerACTypes =
    { type: 'SHOW_APP_DIMMER', dimmerName: string }
    | { type: 'HIDE_APP_DIMMER' };

/* Newbie types */
export type NewbieACTypes =
    { type: 'SET_NEWBIE', value: boolean };

/* Focus on active cell type */
export type FocusOnActiveCellACTypes =
    { type: 'SET_FOCUS_ON_ACTIVE_CELL', isActive: boolean }
    | { type: 'SET_INITIAL_STATE' };

/* User details types */
export type UserDetailsACTypes =
  { type: 'SET_USER_DETAILS', payload: UserDetails}
  | { type: 'SET_INITIAL_STATE' };

/* Auth types */
export type AuthACTypes =
    { type: 'SET_AUTH_DETAILS', details: AuthDetails}
    | { type: 'SET_INITIAL_STATE' };

/* Transaction placeholders modal types */
export type TransactionPlaceholdersModalACTypes =
    { type: 'SET_TRANSACTION_PLACEHOLDERS_MODAL', value: boolean };