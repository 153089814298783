// @flow

import QBOSalesTransaction from './QBOSalesTransaction';

class QBOInvoice extends QBOSalesTransaction {
    salesTermRef: Object;
    trackingNum: Object;

    trackingNum = {
        name: 'trackingNum',
        label: 'Tracking no.',
        type: 'string'
    };

    salesTermRef = {
        value: {
            name: 'value',
            label: 'Terms',
            placeholder: 'Select terms',
            type: 'string',
        }
    };

    getFields() {
        return {
            ...super.getFields(),
            salesTermRef: this.salesTermRef,
            trackingNum: this.trackingNum,
        };
    }
}

export default QBOInvoice;