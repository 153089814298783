// @flow

import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line import/no-cycle
import applicationStore from '../applicationStore';
// eslint-disable-next-line import/no-cycle
import {getPositionAboutParent} from './flowTreeUtils';

import type {
    MatrixCellStateType,
    MatrixCellChildCellType,
    MatrixCellRowStateType,
    CompletedMatrixCellStateType
} from '../constants/flowTyped/flowTypedStates';

// constants for different functions - parts of common matrix iteration
const UPDATE_CELL_FIELD = 'UPDATE_CELL_FIELD';
const SET_CELLS_ACTIVE_TO_INSERT = 'SET_CELLS_ACTIVE_TO_INSERT';
const UPDATE_MATRIX_AFTER_FLOW_NODE_INSERTING = 'UPDATE_MATRIX_AFTER_FLOW_NODE_INSERTING';
const UPDATE_MATRIX_AFTER_FLOW_NODE_DELETING = 'UPDATE_MATRIX_AFTER_FLOW_NODE_DELETING';
export const matrixUpdateFunctionNames = {
    SET_CELLS_ACTIVE_TO_INSERT,
    UPDATE_CELL_FIELD,
    UPDATE_MATRIX_AFTER_FLOW_NODE_INSERTING,
    UPDATE_MATRIX_AFTER_FLOW_NODE_DELETING,
};

/* ------------------- CREATE MATRIX GRID METHODS ---------- */

/* --------- FINAL METHOD----- */
// Generate new cell for horizontal row and add horizontal neighbors
const generateFreeFlowNewMatrixCell = (currentSize: number, cellId: number, index: number) => ({
    cellId,
    leftCellId: index > 0 ? cellId - 1 : null,
    rightCellId: index < currentSize - 1 ? cellId + 1 : null,
    childCells: []
});

/* --------- FINAL METHOD----- */
// Add vertical neighbors for cell from top/bottom rows
const addVerticalCellLinks = (currentRow, topRow, bottomRow) => (
    currentRow.map((matrixCell, index) => (
        {
            ...matrixCell,
            topCellId: topRow ? topRow[index].cellId : null,
            bottomCellId: bottomRow ? bottomRow[index].cellId : null,
        })
    )
);

/* --------- FINAL METHOD----- */
// Add vertical neighbors for current matrix
const generateMatrixCellsVerticalLinks = (currentSize, matrix) => {
    const finishedMatrix = matrix;
    for (let i = 0; i < currentSize; i++) {
        const topRow = i !== 0 && finishedMatrix[i - 1];
        const bottomRow = i !== currentSize - 1 && finishedMatrix[i + 1];
        finishedMatrix[i] = addVerticalCellLinks(finishedMatrix[i], topRow, bottomRow);
    }
    return finishedMatrix;
};

/* --------- FINAL METHOD----- */
// Creating new matrix - works on app start build
export const generateNewMatrix = (currentSize: number = 5) => {
    let matrix = [];
    let cellId = 1;
    for (let i = 0; i < currentSize; i++) {
        let matrixRow = [];
        for (let index = 0; index < currentSize; index++) {
            matrixRow = [...matrixRow, generateFreeFlowNewMatrixCell(currentSize, cellId, index)];
            cellId++;
        }
        matrix = [...matrix, matrixRow];
    }
    return generateMatrixCellsVerticalLinks(currentSize, matrix);
};

/* --------- FINAL METHOD----- */
// Increase current matrix horizontal size
const addCellsToHorizontalRow = (row, lastId: number, currentRowSize: number) => {
    const newFirstRowCell = {cellId: lastId, rightCellId: row[0].cellId, childCells: []};
    const newLastRowCell = {cellId: lastId + 1, leftCellId: row[currentRowSize - 1].cellId, childCells: []};
    const updatedCurrentRow = row.map((cell, index) => {
        if (index === 0) {
            return {...cell, leftCellId: newFirstRowCell.cellId};
        }
        if (index === currentRowSize - 1) {
            return {...cell, rightCellId: newLastRowCell.cellId};
        }
        return cell;
    });
    return [...[newFirstRowCell], ...updatedCurrentRow, ...[newLastRowCell]];
};

/* --------- FINAL METHOD----- */
// Increase current matrix vertical size
const addCellsVerticalRow = (currentSize: number, matrix, lastId: number) => {
    let id = lastId;
    const matrixSize = currentSize + 2;
    let matrixTopRow = [];
    for (let index = 0; index < matrixSize; index++) {
        matrixTopRow = [...matrixTopRow, generateFreeFlowNewMatrixCell(matrixSize, id, index)];
        id++;
    }
    let matrixBottomRow = [];
    for (let index = 0; index < matrixSize; index++) {
        matrixBottomRow = [...matrixBottomRow, generateFreeFlowNewMatrixCell(matrixSize, id, index)];
        id++;
    }
    const newMatrix = [...[matrixTopRow], ...matrix, ...[matrixBottomRow]];
    return {matrixSize, matrix: generateMatrixCellsVerticalLinks(matrixSize, newMatrix)};
};

/* --------- FINAL METHOD----- */
// Increase current matrix size
export const increaseMatrixSize = (currentSize: number, updatedMatrix: Object) => {
    let id = (currentSize ** 2) + 1;
    const matrix = updatedMatrix.map(row => {
        const newRow = addCellsToHorizontalRow(row, id, currentSize);
        id += 2;
        return newRow;
    });
    return addCellsVerticalRow(currentSize, matrix, id);
};

/* ---------------- COMMON MATRIX ITERATION METHODS ----------------- */

/* --------- FINAL METHOD----- */
// Get current matrix grid
const getMatrixGrid = () => applicationStore.getState().flowMatrix.matrix;

export const getMatrixCellById = (id: number) => {
    getMatrixGrid().forEach(row => {
        const resultCell = row.find(matrixCell => matrixCell.cellId === id);
        if (resultCell) {
            return resultCell;
        }
    });
};

/* --------- FINAL METHOD----- */
// Get central matrix cell - cell for flow start
export const getCenterElement = (): ?CompletedMatrixCellStateType => {
    const matrix = getMatrixGrid();
    if (!isEmpty(matrix) && matrix.length === matrix[0].length && matrix[0].length % 2 !== 0) {
        const center = (matrix.length - 1) / 2;
        return matrix[center][center];
    }
};

/* --------- FINAL METHOD----- */
// Get new childCells when flow-tree node is inserted
export const updateChildCells = (newChild: MatrixCellStateType, currentChildCells: Array<MatrixCellChildCellType>) => {
    const newChildParams = {
        childId: newChild.cellId,
        position: getPositionAboutParent(newChild.parentPosition)
    };
    return [...currentChildCells, ...[newChildParams]];
};

/* --------- FINAL METHOD----- */
// Check is cell has all 4 neighbors
export const hasCellEmptyNeighbor = (cell: MatrixCellStateType): boolean => (!cell.leftCellId || !cell.rightCellId || !cell.topCellId || !cell.bottomCellId);

/* --------- FINAL METHOD----- */
// Get cell Neighbors
export const getCellNeighbors = (cell: MatrixCellStateType) => (
    [
        {
            selectedId: cell.leftCellId,
            parentPosition: 'RIGHT'
        },
        {
            selectedId: cell.topCellId,
            parentPosition: 'BOTTOM'
        },
        {
            selectedId: cell.rightCellId,
            parentPosition: 'LEFT'
        },
        {
            selectedId: cell.bottomCellId,
            parentPosition: 'TOP'
        }
    ]
);

/* --------- FINAL METHOD----- */
// Get parent cell Id
export const getParentCellId = (cell: CompletedMatrixCellStateType) => cell[`${cell.parentPosition.toLowerCase()}CellId`];

/* ---------------- UPDATE CURRENT MATRIX CONTENT FUNCTIONS ------------- */

/* ---------- Update functions ---------*/

/* --------- FINAL METHOD----- */
// Update Cell Field by id - returns updated cell if it's id === selected
export const updateMatrixCellField = ({id, field, value, matrixCell}: Object): MatrixCellStateType => (
    matrixCell.cellId === id ? {...matrixCell, [field]: value} : matrixCell
);

/* --------- FINAL METHOD----- */
// Make Neighbors of active cell ready to insert flow-tree node
const setCellsActiveToInsert = ({blockType, parentCellNeighbors, parentCellBlockType, conditionType, matrixCell}: Object): MatrixCellStateType => {
    const selectedNeighbor = parentCellNeighbors.find(cell => cell.selectedId === matrixCell.cellId);
    if (selectedNeighbor && !matrixCell.flowNodeId && matrixCell.blockType !== 'Start') {
        return {
            ...matrixCell,
            isActiveToRender: true,
            parentPosition: selectedNeighbor.parentPosition,
            blockType,
            parentCellBlockType,
            conditionType
        };
    }
    if (matrixCell.isActiveToRender) {
        return {
            ...matrixCell,
            isActiveToRender: false,
            parentPosition: null,
            blockType: null,
            parentCellBlockType: null,
            conditionType: null
        };
    }
    return matrixCell;
};

/* --------- FINAL METHOD----- */
// Update after flow-tree node inserting
export const updateMatrixAfterFlowNodeInserting = ({cellToInsert, parentCellId, matrixCell}: Object): MatrixCellStateType => {
    if (cellToInsert.cellId === matrixCell.cellId) {
        return {
            ...cellToInsert,
            isActiveToRender: false,
            flowNodeId: matrixCell.cellId
        };
    }
    if (matrixCell.cellId === parentCellId) {
        return {
            ...matrixCell,
            childCells: updateChildCells(cellToInsert, matrixCell.childCells)
        };
    }
    if (matrixCell.isActiveToRender) {
        return {
            ...matrixCell,
            isActiveToRender: false,
            parentPosition: null,
            blockType: null,
            parentCellBlockType: null,
            conditionType: null
        };
    }
    return matrixCell;
};

const updateMatrixAfterFlowNodeDeleting = ({cellToDelete, parentCellId, matrixCell}: Object): MatrixCellStateType => {
    if (cellToDelete.cellId === matrixCell.cellId) {
        return {
            ...matrixCell,
            flowNodeId: null,
            parentPosition: null,
            blockType: null,
            parentCellBlockType: null,
            conditionType: null
        };
    }
    if (matrixCell.isActiveToRender) {
        return {
            ...matrixCell,
            isActiveToRender: false
        };
    }
    if (parentCellId === matrixCell.cellId) {
        return {
            ...matrixCell,
            childCells: matrixCell.childCells.filter(child => child.childId !== cellToDelete.cellId)
        };
    }
    return matrixCell;
};

/* ---------- Common ----------------- */

/* --------- FINAL METHOD----- */
// Update function caller
export const callMatrixUpdateFunc = (methodName: string, args: Object): MatrixCellStateType => {
    switch (methodName) {
        case UPDATE_CELL_FIELD:
            return updateMatrixCellField(args);
        case SET_CELLS_ACTIVE_TO_INSERT:
            return setCellsActiveToInsert(args);
        case UPDATE_MATRIX_AFTER_FLOW_NODE_INSERTING:
            return updateMatrixAfterFlowNodeInserting(args);
        case UPDATE_MATRIX_AFTER_FLOW_NODE_DELETING:
            return updateMatrixAfterFlowNodeDeleting(args);
        default:
            return args.matrixCell;
    }
};

/* --------- FINAL METHOD----- */
// Common outside called update method and base matrix iterator
export const updateMatrixCells = (methodName: string, args: Object) => (
    getMatrixGrid().map(row => row.map(matrixCell => callMatrixUpdateFunc(methodName, {...args, matrixCell})))
);

export const updateMatrixFromRecursion = (methodName: string, matrix: Array<MatrixCellRowStateType>, args: Object): Array<MatrixCellRowStateType> => (
    matrix.map<MatrixCellRowStateType>(row => row.map(matrixCell => callMatrixUpdateFunc(methodName, {...args, matrixCell})))
);

/* --------- FINAL METHOD----- */
// Update Matrix after flow-node inserting
export const refreshMatrixAfterNodeInserting = (args: Object) => {
    const {matrixSize} = applicationStore.getState().flowMatrix;
    const {cellToInsert} = args;
    const newArgs = {...args, parentCellId: getParentCellId(cellToInsert)};

    // Check should we increase matrix size
    const shouldIncreaseMatrixSize: boolean = hasCellEmptyNeighbor(cellToInsert);
    // update current matrix cells - remove active status, parentPosition, add flowNode
    const matrix = updateMatrixCells(UPDATE_MATRIX_AFTER_FLOW_NODE_INSERTING, newArgs);
    return shouldIncreaseMatrixSize ? increaseMatrixSize(matrixSize, matrix) : {matrixSize, matrix};
};

/* --------- FINAL METHOD----- */
/* Positioning relative to the starting element */
export const positionOfStart = (): void => {
    const matrix = getMatrixGrid();
    const matrixWidth = matrix.length * 300;
    if (matrix.length < 9) {
        window.scrollTo(0, matrixWidth / 5);
    } else {
        window.scrollTo(matrixWidth / 3, matrixWidth / 3);
    }
};

/* Be careful when change blocktype logic  */
export const deleteEmptyRowsColumns = () => {
    const matrix = getMatrixGrid();
    const newMatrix = matrix.map(row => row.map(cell => {
        if (cell.isActiveToRender) {
            return {...cell, isActiveToRender: false, blockType: null};
        }
        return cell;
    }))
        .filter(row => findIndex(row, cell => cell.blockType) > -1);
    let columnHasBlock = false;
    for (let i = 0; i < newMatrix[0].length; i++) {
        columnHasBlock = false;
        newMatrix.forEach(row => { // eslint-disable-line
            if (row[i].blockType) {
                columnHasBlock = true;
            }
        });
        if (!columnHasBlock) {
            newMatrix.forEach(
                row => row.splice(i, 1)
            );
            i--;
        }
    }
    return {matrixSize: newMatrix[0].length, matrix: newMatrix};
};
