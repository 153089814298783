// @flow

import {appSideBarsDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {AppSideBarsStateType} from '../../constants/flowTyped/flowTypedStates';
import type {AppSideBarsACTypes} from '../../constants/flowTyped/flowTypedACTypes';

export const FLOW_TEMPLATES_MAIN_SIDE_BAR = 'FLOW_TEMPLATES_MAIN_SIDE_BAR';
export const FLOW_TEMPLATES_MESSAGES_SIDE_BAR = 'FLOW_TEMPLATES_MESSAGES_SIDE_BAR';
export const FLOW_ERRORS_SIDE_BAR = 'FLOW_ERRORS_SIDE_BAR';

const initialState = {
    [FLOW_TEMPLATES_MAIN_SIDE_BAR]: false,
    [FLOW_TEMPLATES_MESSAGES_SIDE_BAR]: false,
    [FLOW_ERRORS_SIDE_BAR]: false,
};

function appSideBars(state: AppSideBarsStateType = initialState, action: AppSideBarsACTypes): AppSideBarsStateType {
    if (action.type === appSideBarsDispatchTypes.SET_IS_SIDEBAR_SHOWN) {
        return {
            ...state,
            ...action.changedSideBars
        };
    }
    return state;
}

export default appSideBars;