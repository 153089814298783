// @flow

import QBOBasicTransaction from '../QBOBasicTransaction';
import QBOItemBasedSalesLineDetail from '../lineItems/QBOItemBasedSalesLineDetail';

class QBOSalesTransaction extends QBOBasicTransaction {
    customerRef: Object;
    productLine: Object;
    customerMemo: Object;
    classRef: Object;

    customerRef = {
        value: {
            name: 'value',
            label: 'Customer',
            type: 'string',
            placeholder: 'Current customer'
        }
    };

    classRef = {
        value: {
            name: 'value',
            label: 'Class',
            type: 'string',
            placeholder: 'Select class'
        }
    };

    customerMemo = {
        value: {
            name: 'value',
            label: 'Message displayed to customer',
            type: 'string',
            maxLength: 4000
        }
    };

    amount = {
        name: 'amount',
        label: 'Amount',
        type: 'number',
        minValue: 0
    };

    productLine = {
        ...new QBOItemBasedSalesLineDetail().getFields(),
        productLineName: 'salesItemLineDetail',
        amount: this.amount
    };

    getFields() {
        return {
            ...super.getFields(),
            customerRef: this.customerRef,
            productLine: this.productLine,
            customerMemo: this.customerMemo,
            classRef: this.classRef,
        };
    }
}

export default QBOSalesTransaction;