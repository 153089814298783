// @flow

import {spinnerTypes} from '../../constants/dispatch/dispatchTypes';
import type {SpinnerACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import type {SpinnerStateType} from '../../constants/flowTyped/flowTypedStates';

const initialState = {
    active: false,
    text: ''
};

function spinner(state: SpinnerStateType = initialState, action: SpinnerACTypes): SpinnerStateType {
    switch (action.type) {
        case spinnerTypes.RUN_SPINNER:
            return {active: true, text: action.payload};
        case spinnerTypes.STOP_SPINNER:
            return initialState;
        default:
            return state;
    }
}

export default spinner;