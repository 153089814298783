// @flow

import sortBy from 'lodash/sortBy';
import capitalize from 'lodash/capitalize';
import applicationStore from '../../applicationStore';

// Transactions
import QBOInvoice from './entities/transactions/sales/QBOInvoice';
import QBORefundReceipt from './entities/transactions/sales/QBORefundReceipt';
import QBOSalesReceipt from './entities/transactions/sales/QBOSalesReceipt';
import QBOEmployee from './entities/contacts/QBOEmployee';
import QBOVendor from './entities/contacts/QBOVendor';
import QBOCustomer from './entities/contacts/QBOCustomer';
import QBOItem from './entities/common/QBOItem';
import QBOCashExpense from './entities/transactions/expenses/QBOCashExpense';
import QBODeposit from './entities/transactions/sales/QBODeposit';
import QBOTransferTransaction from './entities/transactions/sales/QBOTransferTransaction';

import type {ContactItemType} from '../flowTyped/flowTypedStates';

export const CURRENT_VALUE_NAME = 'Current';
export const ENTITY_UPDATE_ACTION = 'UPDATE';
export const ENTITY_UPDATE_LINE_ACTIONS = 'UPDATE_LINE';
export const UPDATE_LINE_US = 'UPDATE_LINE_US';
export const UPDATE_LINE_NON_US = 'UPDATE_LINE_NON_US';
export const UPDATE_CURRENT_US = 'UPDATE_US';
export const UPDATE_CURRENT_NON_US = 'UPDATE_NON_US';

export const EXPENSE_NAME = 'CASH_PURCHASE';
export const DEPOSIT_NAME = 'DEPOSIT';
export const EXPENSES_NAME = 'EXPENSE';
export const INVOICE_NAME = 'INVOICE';
export const PURCHASE_ORDER_NAME = 'PURCHASE_ORDER';
export const REFUND_RECEIPT_NAME = 'REFUND_RECEIPT';
export const SALES_RECEIPT_NAME = 'SALES_RECEIPT';
export const PAYMENT = 'PAYMENT'
export const TRANSFER = 'TRANSFER';
export const PAYMENT_LINK = 'PAYMENT_LINK';

export const ITEM_NAME = 'ITEM';
export const CUSTOMER_NAME = 'CUSTOMER';
export const VENDOR_NAME = 'VENDOR';
export const EMPLOYEE_NAME = 'EMPLOYEE';

export const QBD_SALES_RECEIPT_NAME = 'SALES_RECEIPT';
export const QBD_CREDIT_MEMO_NAME = 'CREDIT_MEMO';
export const QBD_CHECK_NAME = 'CHECK';
export const QBD_INVOICE_NAME = 'INVOICE';

export const SALES_TRANSACTION_QBO_ENTITIES = [
    INVOICE_NAME,
    REFUND_RECEIPT_NAME,
    SALES_RECEIPT_NAME
];

export const PURCHASE_EXTENDED_QBO_ENTITIES = [
    EXPENSE_NAME
];

export const EXPENSE_TRANSACTION_QBO_ENTITIES = [
    ...PURCHASE_EXTENDED_QBO_ENTITIES,
    PURCHASE_ORDER_NAME
];

export const TRANSACTION_TYPE_QBO_ENTITIES = [
    ...SALES_TRANSACTION_QBO_ENTITIES,
    ...EXPENSE_TRANSACTION_QBO_ENTITIES,
    TRANSFER
];

export const CONTACT_QBO_ENTITIES = [
    CUSTOMER_NAME,
    VENDOR_NAME,
    EMPLOYEE_NAME,
];

// Taxable entities
export const TAXABLE_ENTITIES = [INVOICE_NAME, SALES_RECEIPT_NAME, REFUND_RECEIPT_NAME];

export const AVAILABLE_UPDATE_LINE_ENTITIES = [EXPENSE_NAME, INVOICE_NAME, SALES_RECEIPT_NAME, REFUND_RECEIPT_NAME, DEPOSIT_NAME];

export const providerActionEntities = [
    {
        id: EXPENSE_NAME,
        text: 'Expense'
    },
    {
        id: CUSTOMER_NAME,
        text: 'Customer'
    },
    /* {
        id: EMPLOYEE_NAME,
        text: 'Employee'
    },*/
    {
        id: DEPOSIT_NAME,
        text: 'Deposit'
    },
    {
        id: INVOICE_NAME,
        text: 'Invoice'
    },
    {
        id: ITEM_NAME,
        text: 'Product or Service'
    },
    {
        id: REFUND_RECEIPT_NAME,
        text: 'Refund Receipt'
    },
    {
        id: SALES_RECEIPT_NAME,
        text: 'Sales Receipt'
    },
    {
        id: VENDOR_NAME,
        text: 'Vendor'
    },
    {
        id: TRANSFER,
        text: 'Transfer'
    },
    {
        id: PAYMENT,
        text: 'Payment'
    }
];

export const synderActionEntities = [
    {
        id: EXPENSES_NAME,
        text: 'Expense'
    },
    {
        id: DEPOSIT_NAME,
        text: 'Deposit'
    }
];

export const qbdActionEntities = [
    {
        id: QBD_SALES_RECEIPT_NAME,
        text: 'Sales receipt'
    },
    {
        id: QBD_INVOICE_NAME,
        text: 'Invoice'
    },
    {
        id: QBD_CREDIT_MEMO_NAME,
        text: 'Credit memo'
    },
    {
        id: QBD_CHECK_NAME,
        text: 'Check'
    },
];

export const AVAILABLE_SYNDER_ENTITIES = [DEPOSIT_NAME, EXPENSES_NAME];
export const AVAILABLE_QBD_ENTITIES = [QBD_SALES_RECEIPT_NAME, QBD_INVOICE_NAME, QBD_CREDIT_MEMO_NAME, QBD_CHECK_NAME];

export const transformSimpleOptionsToPaginateOptions = (options: Array<{key: any, value: any, text: string}>) => (
  options.map(item => ({value: item.value, label: item.text}))
);

export const getProviderEntitiesDropdownValues = (list: Array<Object>, shouldAddClean: boolean = true, customSortField?: string, customIdField?: string) => {
    const options = [];
    sortBy(list, customSortField || 'displayName').forEach(listItem => {
        if (listItem && listItem[customIdField || 'id']) {
            const id = listItem[customIdField || 'id'];
            options.push({
                key: id,
                value: id,
                text: listItem[customSortField || 'displayName']
            });
        }
    });
    if (shouldAddClean) {
        options.unshift({
            key: 'Clean option',
            value: null,
            text: 'Clean Selected...'
        });
    }
    return options;
};

export const getContactDropdownList = (
  contacts: Array<ContactItemType>, contactType: ?string, shouldAddClean: boolean = true
) => getProviderEntitiesDropdownValues(contacts, shouldAddClean);

export const getSelectedEntityFields = (selectedProviderEntity: ?string) => {
    let selectedEntity;
    switch (selectedProviderEntity) {
        case EXPENSE_NAME:
            selectedEntity = new QBOCashExpense();
            break;
        case DEPOSIT_NAME:
            selectedEntity = new QBODeposit();
            break;
        case INVOICE_NAME:
            selectedEntity = new QBOInvoice();
            break;
        case ITEM_NAME:
            selectedEntity = new QBOItem();
            break;
        case REFUND_RECEIPT_NAME:
            selectedEntity = new QBORefundReceipt();
            break;
        case SALES_RECEIPT_NAME:
            selectedEntity = new QBOSalesReceipt();
            break;
        case CUSTOMER_NAME:
            selectedEntity = new QBOCustomer();
            break;
        case VENDOR_NAME:
            selectedEntity = new QBOVendor();
            break;
        case EMPLOYEE_NAME:
            selectedEntity = new QBOEmployee();
            break;
        case TRANSFER:
            selectedEntity = new QBOTransferTransaction();
            break;
        // no default
    }
    return selectedEntity && selectedEntity.getFields();
};

// Item
export const INVENTORY_ITEM_TYPE = 'Inventory';
export const SERVICE_ITEM_TYPE = 'Service';
export const NON_INVENTORY_ITEM_TYPE = 'NonInventory';
export const ITEM_INVENTORY_TYPES = [INVENTORY_ITEM_TYPE, NON_INVENTORY_ITEM_TYPE, SERVICE_ITEM_TYPE];

export const LINE_ITEM_UPDATE_ALL_ACTION = 'UPDATE_ALL';
// export const LINE_ITEM_UPDATE_ACTION = 'UPDATE';
// export const LINE_ITEM_CREATE_ACTION = 'CREATE';

// Tax
export const NO_TAX_OPTION = 'NotApplicable';

export const DO_NOT_UPDATE_TAX = 'Do not update tax';

export const UPDATE_ALL = 'UPDATE_ALL';

export const UPDATE_ALL_LABEL = 'Update all';

export const ACCOUNT_BASED_EXPENSE_LINE_DETAIL = 'accountBasedExpenseLineDetail';
export const SALES_ITEM_LINE_DETAIL = 'salesItemLineDetail';

export const UPDATE_CURRENT_ENTITIES_WITHOUT_PLACEHOLDERS = [TRANSFER];

export const QBO_ENTITIES_REQUIRE_CUSTOMER_REF = [
    SALES_RECEIPT_NAME, INVOICE_NAME, REFUND_RECEIPT_NAME
];
export const QBO_ENTITIES_REQUIRE_ENTITY_REF = [
    EXPENSE_NAME
];