// @flow

import {modalsDispatchTypes, SET_INITIAL_STATE} from '../../constants/dispatch/dispatchTypes';
import type {ModalStateType} from '../../constants/flowTyped/flowTypedStates';
import type {ModalACTypes} from '../../constants/flowTyped/flowTypedACTypes';

const initialState = {
    isModalShown: false
};

function modals(state: ModalStateType = initialState, action: ModalACTypes): ModalStateType {
    switch (action.type) {
        case modalsDispatchTypes.SHOW_MODAL:
            return {...action.payload, isModalShown: true};
        case modalsDispatchTypes.HIDE_MODAL:
        case SET_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
}

export default modals;