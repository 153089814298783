// @flow

import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
// flow-disable-next-line
import {ContentState, convertFromRaw, convertToRaw, EditorState} from 'draft-js';

export const removeNonDigits = (value: string): ?string => (value.toString().replace(/[a-zA-Z]|[\\/!@#$%^&*()_+=?<>| ]/g, ''));

export const convertValueToInteger = (value: string): ?string => (value.toString().replace(/\D/g, ''));

export const parsingTextForCursor = (oldText: string | null, textToInsert: string, cursorPosition: number) => (oldText ? `${oldText.slice(0, cursorPosition)} {{${textToInsert}}} ${oldText.slice(cursorPosition)}` : `{{${textToInsert}}}`);

export const convertTextFromEmailEditor = (text: string, positionCursorOfRow: number, selectedField: string, content: string, cursorPosition: number) => {
    const contentBlock = text && htmlToDraft(text);
    if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        const rows = convertToRaw(editorState.getCurrentContent());
        const newRows = {
            ...rows,
            blocks: rows.blocks.map((row, i) => {
                if (i === positionCursorOfRow) {
                    if (selectedField) {
                        return {
                            ...row,
                            text: parsingTextForCursor(row.text, `${selectedField}`, cursorPosition)
                        };
                    }
                    return {
                        ...row,
                        text: parsingTextForCursor(row.text, `${content}`, cursorPosition)
                    };
                }
                return {
                    ...row
                };
            })
        };
        const newEditorState = EditorState.createWithContent(convertFromRaw(newRows));
        return draftToHtml(convertToRaw(newEditorState.getCurrentContent())).replace('&nbsp;', ' ');
    }
};