// @flow
import React, {Suspense, lazy} from 'react';
import {connect} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import Dimmer from 'semantic-ui-react/dist/es/modules/Dimmer/Dimmer';
import Loader from 'semantic-ui-react/dist/es/elements/Loader/Loader';

import AppInitializer from './views/AppInitializer';

const SessionEndedPage = lazy(() => import(/* webpackChunkName: 'SessionEndedPage' */ './views/SessionEndedPage'));
const FlowRoot = lazy(() => import(/* webpackChunkName: 'FlowRoot' */ './views/FlowRoot'));

const AppLoader =
    <Dimmer page active>
        <Loader size="huge" content="Please, wait"/>
    </Dimmer>;

const App = props => (
    <div>
        <Dimmer page active={props.spinner.active}>
            <Loader size="huge" content={props.spinner.text}/>
        </Dimmer>
        <ToastContainer/>
        <BrowserRouter>
            <Suspense fallback={AppLoader}>
                <Switch>
                    <Route path="/init/:credentials"
                           component={AppInitializer}
                    />
                    <Route exact
                           path="/createFlow"
                           component={FlowRoot}
                    />
                    <Route path="/editFlow/:id"
                           component={FlowRoot}
                    />
                    <Route path="/template/:templateId"
                           component={FlowRoot}
                    />
                    <Route path="/end"
                           component={SessionEndedPage}
                           exact
                    />
                </Switch>
            </Suspense>
        </BrowserRouter>
    </div>
);
const mapStateToProps = state => ({
    spinner: state.spinner
});

export default connect(mapStateToProps)(App);
