import type {AuthDetails} from '../../constants/flowTyped/flowTypedStates';
import type {AuthACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import {AuthDispatchTypes} from '../../constants/dispatch/dispatchTypes';

const initialState = {};

const authReducer = (state: AuthDetails = initialState, action: AuthACTypes) => {
    if (action.type === AuthDispatchTypes.SET_AUTH_DETAILS) {
        return {
            ...state,
            ...action.details
        };
    }
    return state;
};

export default authReducer;