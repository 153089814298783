// @flow

import QBOLineDetail from './QBOLineDetail';

class QBOAccountLineDetail extends QBOLineDetail {
    accountRef: Object;
    amount: Object;
    taxCodeRef: Object;

    accountRef = {
        value: {
            name: 'value',
            label: 'Category',
            placeholder: 'Select category',
            type: 'string',
            isRequired: true
        }
    };

    amount = {
        name: 'amount',
        label: 'Amount',
        type: 'number',
        isRequired: true
    };

    taxCodeRef = {
        value: {
            name: 'value',
            label: 'Tax',
            type: 'string',
            placeholder: 'Tax code'
        }
    };

    getFields() {
        return {
            ...super.getFields(),
            accountRef: this.accountRef,
            amount: this.amount,
            taxCodeRef: this.taxCodeRef
        };
    }
}

export default QBOAccountLineDetail;