// @flow
import {apiFlowlessTemplatesRequest} from '../../api/api';
import {setTemplateGroups} from './actionCreators';
// eslint-disable-next-line import/no-cycle
import {showErrorTooltip} from '../commonActions/commonAppActions';

import type {Dispatch, TemplateGroupsStateType} from '../../constants/flowTyped/flowTypedStates';

type Response = {
    templateGroups?: Array<TemplateGroupsStateType>
}

export const apiLoadTemplateGroups = async (dispatch: Dispatch) => {
    const res: Response = await apiFlowlessTemplatesRequest('/templatesGroups');
    if (res.templateGroups) {
        dispatch(setTemplateGroups(res.templateGroups));
        return res.templateGroups;
    }
    showErrorTooltip('Can\'t load server data. Please, try again later or contact our support-team');
};