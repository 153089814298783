// @flow

import QBOSalesTransaction from './QBOSalesTransaction';

class QBOSalesReceipt extends QBOSalesTransaction {
    depositToAccountRef: Object;
    paymentMethodRef: Object;
    trackingNum: Object;

    depositToAccountRef = {
        value: {
            name: 'value',
            label: 'Deposit to',
            placeholder: 'Select account',
            type: 'string',
            isRequired: false
        }
    };

    trackingNum = {
        name: 'trackingNum',
        label: 'Tracking no.',
        type: 'string'
    };

    paymentMethodRef = {
        value: {
            name: 'value',
            label: 'Payment method',
            placeholder: 'Select payment method',
            type: 'string',
            isRequired: false
        }
    };

    getFields() {
        return {
            ...super.getFields(),
            depositToAccountRef: this.depositToAccountRef,
            paymentMethodRef: this.paymentMethodRef,
            trackingNum: this.trackingNum,
        };
    }
}

export default QBOSalesReceipt;