// @flow

import {transactionPlaceholdersModalDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {TransactionPlaceholdersModalACTypes} from '../../constants/flowTyped/flowTypedACTypes';

function transactionPlaceholdersModal(state: boolean = false, action: TransactionPlaceholdersModalACTypes) {
    if (action.type === transactionPlaceholdersModalDispatchTypes.SET_TRANSACTION_PLACEHOLDERS_MODAL) {
        return action.value;
    }
    return state;
}

export default transactionPlaceholdersModal;