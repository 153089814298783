// @flow
import {flowMatrixDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {FlowMatrixStateType, MatrixCellRowStateType, CompletedMatrixCellStateType} from '../../constants/flowTyped/flowTypedStates';
import type {FlowMatrixACTypes, ActiveMatrixCellACTypes} from '../../constants/flowTyped/flowTypedACTypes';

export const updateFlowMatrixCells = (newMatrixGrid: Array<MatrixCellRowStateType>): FlowMatrixACTypes => ({
    type: flowMatrixDispatchTypes.UPDATE_FLOW_MATRIX_CELLS,
    newMatrixGrid
});

export const fullMatrixUpdate = (newMatrix: FlowMatrixStateType): FlowMatrixACTypes => ({
    type: flowMatrixDispatchTypes.SET_FLOW_MATRIX,
    newMatrix
});

export const setCellActive = (activeCell: CompletedMatrixCellStateType): ActiveMatrixCellACTypes => ({
    type: flowMatrixDispatchTypes.SET_ACTIVE_MATRIX_CELL,
    activeCell
});

export const refreshActiveCell = (): ActiveMatrixCellACTypes => ({
    type: flowMatrixDispatchTypes.REFRESH_ACTIVE_CELL
});
