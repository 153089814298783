// @flow
import {toast} from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import domtoimage from 'dom-to-image';
import applicationStore from '../../applicationStore';
import {
    runSpinner,
    setLeftMenuActiveContent,
    stopSpinner,
    changeAppSideBarsIsShown, setAuthDetails, setCurrentProvider, setFocusOnActiveCell, setUserDetails
} from './commonAppActionCreators';
import {
    apiFlowlessTemplatesRequest,
    apiRequest,
    apiSynderRequest,
    getSessionStorageValue, redirectToCreateFlow,
    redirectToWebHome
} from '../../api/api';
import {fullMatrixUpdate, refreshActiveCell} from '../flowMatrix/actionCreators';
import {saveFlowErrors} from '../flowErrors/actionCreators';
import {
    calculateCountBlocksOfFlow,
    checkFlowOnError,
    fieldsTranslater,
    updateFlowAndMatrix
} from '../../utils/flowTreeUtils';
import {deleteEmptyRowsColumns} from '../../utils/flowMatrixUtils';
// eslint-disable-next-line import/no-cycle
import {apiLoadFlowTemplatesList} from '../flowTemplates/actions';
import {apiLoadSelectEventConditionFieldsList} from '../flowOperations/actions';
import {changeActiveCell} from '../flowMatrix/actions';
// eslint-disable-next-line import/no-cycle
import {addFirstFlowElement, setFlowId} from '../flowTree/actionCreators';
// eslint-disable-next-line import/no-cycle
import {apiLoadEvents} from '../events/actions';
import {setFlowEventEntityAction} from '../events/actionCreators';
import {removeTempAutoSavedFlow} from '../../utils/flowAutoSaveUtils';

import {FLOW_ERRORS_SIDE_BAR} from '../../reducers/common/appSideBarsReducer';
import {leftMenuContentConstants} from '../../constants/menu/leftMenuContentConstants';

import type {
    Dispatch,
    FlowTreeStateType,
    ErrorInBlockStateType
} from '../../constants/flowTyped/flowTypedStates';
// eslint-disable-next-line import/no-cycle
import {apiLoadTemplateGroups} from '../templateGroups/actions';
import {setFlowTemplatesList} from '../flowTemplates/actionCreators';
import {
    CurrentProviderStateType,
    PROVIDER_INTUIT,
    PROVIDER_INTUIT_DESKTOP,
    PROVIDER_SYNDER
} from '../../constants/flowTyped/flowTypedStates';
// eslint-disable-next-line import/no-cycle
import {apiLoadContactsInfo} from '../contacts/actions';
import {TEMPLATE_AVAILABLE_PROVIDERS} from '../../constants/common';

type SaveFlowResponseType = {
    id?: string,
    errors?: Array<ErrorInBlockStateType>,
    error?: ErrorInBlockStateType,
}

const showInfoTooltip = (text: string, tooltipType: string) => {
    const tooltipConfig = {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000
    };
    if (tooltipType === 'Error') {
        toast.error(text, tooltipConfig);
    } else if (tooltipType === 'Success') {
        toast.success(text, tooltipConfig);
    }
};

export const showErrorTooltip = (text: string) => showInfoTooltip(text, 'Error');

export const showSuccessTooltip = (text: string) => showInfoTooltip(text, 'Success');

/* ---------------------- Save Flow ------------------ */

export const dispatchingIfErrors = (dispatch: Dispatch, errors: Array<ErrorInBlockStateType>) => {
    dispatch(saveFlowErrors(errors));
    dispatch(changeAppSideBarsIsShown({
        [FLOW_ERRORS_SIDE_BAR]: true
    }));
    dispatch(stopSpinner());
    dispatch(setLeftMenuActiveContent(null));
};

const getFlowImage = () => async (dispatch: Dispatch) => {
    await dispatch(fullMatrixUpdate(deleteEmptyRowsColumns()));
    const node = document.getElementById('flow');
    if (node) {
        node.style.background = '#f4f6f8';
        // flow-disable-next-line
        node.style.zoom = '1';
        node.style.transform = 'scale(1)';
    }
    return domtoimage.toPng(node);
};

const sendFlowToApi = async (flowTosend: FlowTreeStateType, flowId: ?string) => {
    const url = flowId ? `/v1/flow/${flowId}` : '/v1/flow';
    const method = flowId ? 'PUT' : 'POST';
    const response = await apiRequest(url, {
        method,
        body: JSON.stringify(flowTosend)
    });
    return response;
};

export const saveFlow = (withClose: boolean, withRedirectToCreateFlow: boolean, errorCallback?: () => void) => async (dispatch: Dispatch) => {
    await dispatch(refreshActiveCell());
    dispatch(runSpinner('Save rule...'));
    const errors = checkFlowOnError();
    if (isEmpty(errors)) {
        const {flow, flowMatrix} = applicationStore.getState();
        const backupForMatrix = JSON.stringify(flowMatrix);
        let newFlow = {
            ...flow,
            block: fieldsTranslater(flow.block, false)(dispatch),
            companyId: flow.companyId || getSessionStorageValue('companyId')
        };
        const base64Data = getFlowImage()(dispatch);
        base64Data.then(async dataUrl => {
            const base64 = dataUrl.slice(dataUrl.indexOf(',') + 1);
            newFlow = {
                ...newFlow,
                base64Diagram: base64
            };
            const response: SaveFlowResponseType | FlowTreeStateType = await sendFlowToApi(newFlow, newFlow.id);
            if (response.id) {
                dispatch(setFlowId(response.id));
                if (!withClose) {
                    if (!newFlow.id && response.status === 'DISABLED') {
                        showSuccessTooltip('The rule has been saved. However, it is disabled, as you don\'t have enough rules purchased.');
                    } else {
                        showSuccessTooltip(`Rule ${newFlow.id ? 'updated' : 'saved'}`);
                    }
                }
                removeTempAutoSavedFlow();
                if (withClose) redirectToWebHome();
                if (withRedirectToCreateFlow) redirectToCreateFlow();
            } else {
                showErrorTooltip(`Your rule hasn't been ${newFlow.id ? 'updated' : 'created'}. Please, check your data and try again, or contact our support-team.`);
            }
            dispatch(stopSpinner());
            dispatch(setLeftMenuActiveContent(null));
            dispatch(fullMatrixUpdate(JSON.parse(backupForMatrix)));
            dispatch(setFocusOnActiveCell(false));
        });
    } else {
        dispatchingIfErrors(dispatch, errors);
        errorCallback && errorCallback();
    }
};

/* ---------------------- Data loading ------------------ */

export const apiFetchQbdData = () => async (dispatch: Dispatch) => {
    dispatch(runSpinner('Loading data... Please, wait'));
    const socialTokenId = getSessionStorageValue('companyId');
    const response = await apiSynderRequest(
        `/v1/companies/${socialTokenId}/startCacheQuickBooksDesktopEntities`,
        {method: 'POST'}
    );
    if (response.success) {
        showSuccessTooltip('Success! Data fetching has started.');
    } else {
        showErrorTooltip('Can\'t fetch data from QuickBooks Desktop');
    }
    dispatch(stopSpinner());
};

export const setExternalFlowAsFlowTree = (externalFlow: FlowTreeStateType, shouldTranslateMessageFields: boolean) => async (dispatch: Dispatch) => {
    const {id, templateId, block, eventId} = externalFlow;
    dispatch(runSpinner('Loading data... Please, wait'));
    const event = applicationStore.getState().events.eventsList.find(event => event.id === eventId);
    dispatch(setFlowEventEntityAction(event));
    await apiLoadContactsInfo()(dispatch);
    await apiLoadSelectEventConditionFieldsList([event.entity], [])(dispatch);

    const {newFlow, updatedFlowMatrix, center} = updateFlowAndMatrix(
        {
            ...externalFlow,
            block: shouldTranslateMessageFields ? fieldsTranslater(externalFlow.block, !!(id || templateId))(dispatch) : block
        }
    );

    const blockCount = calculateCountBlocksOfFlow(newFlow);
    dispatch(addFirstFlowElement(newFlow, blockCount + 1));
    dispatch(fullMatrixUpdate(updatedFlowMatrix));
    if (center) {
        changeActiveCell(center)(dispatch);
    }
    dispatch(stopSpinner());
};

export const loadFlowEditData = (id: string) => async (dispatch: Dispatch) => {
    dispatch(runSpinner('Loading data... Please, wait'));
    const externalFlow: FlowTreeStateType = await apiRequest(`/v1/flow/${id}`);
    if (externalFlow.id) {
        setExternalFlowAsFlowTree(externalFlow, true)(dispatch);
    } else {
        dispatch(setLeftMenuActiveContent(leftMenuContentConstants.LEFT_MENU_TRIGGER_BLOCK));
        dispatch(stopSpinner());
        showErrorTooltip('Can\'t load selected rule');
    }
};

export const apiLoadIsSynderAccounting = () => async (dispatch: Dispatch) => {
    const res: Response = await apiRequest('/v1/providerData/currentProvider');
    if (res && [PROVIDER_SYNDER, PROVIDER_INTUIT, PROVIDER_INTUIT_DESKTOP].includes(res.provider)) {
        dispatch(setCurrentProvider(res.provider));
    }
};

export const apiLoadInitialAppData = (currentProvider: CurrentProviderStateType) => async (dispatch: Dispatch) => {
    dispatch(runSpinner('Loading data... Please, wait'));

    const baseApiRequests = [
        apiLoadEvents()(dispatch),
        apiLoadIsSynderAccounting()(dispatch)
    ];

    const isTemplatesAvailable = TEMPLATE_AVAILABLE_PROVIDERS.includes(currentProvider);

    if (isTemplatesAvailable) {
        baseApiRequests.unshift(apiLoadFlowTemplatesList());
        baseApiRequests.unshift(apiLoadTemplateGroups(dispatch));
    }

    const responses = await Promise.all(baseApiRequests);
    if (isTemplatesAvailable) {
        const [templateGroups, templates] = responses;
        if (templateGroups.length && templates.length) {
            dispatch(setFlowTemplatesList(
                templates.map(template => ({
                    ...template,
                    group: {
                        name: templateGroups.find(group => group.id === template.groupId)?.name
                    }
                }))
            ));
        }
    }
};

export const apiLoadOrganizationDetails = () => async (dispatch: Dispatch) => {
    const currentOrganization = await apiSynderRequest('/v1/organizations/current');
    if (currentOrganization) {
        const orgDetails = {
            ...currentOrganization,
            orgId: currentOrganization?.id,
        };
        dispatch(setUserDetails(orgDetails));
        return orgDetails;
    }
};

export const apiLoadUserData = async () => {
    const userData = await apiSynderRequest('/v1/users/current');
    if (userData) {
        return userData;
    }
};

/* Auth */
export const apiLoadJwtAuthData = () => async (dispatch: Dispatch) => {
    const csrftokenResponse = await apiFlowlessTemplatesRequest('/csrf-token');
    if (csrftokenResponse && csrftokenResponse.csrfToken) {
        dispatch(setAuthDetails({
            authenticated: true,
            csrfFlowlessTemplatesToken: csrftokenResponse.csrfToken
        }));
    } else {
        dispatch(setAuthDetails({
            authenticated: false,
        }));
    }
};
