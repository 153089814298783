// @flow

import applicationStore from '../../applicationStore';
import {
    updateFlowMatrixAfterDeleteFlowNode,
    updateFlowMatrixAfterNodeInserting,
    updateFlowMatrixCell
} from '../flowMatrix/actions';

import {updateFlowName, addFirstFlowElement, updateFlowBlock} from './actionCreators';
import {fullMatrixUpdate, refreshActiveCell, setCellActive} from '../flowMatrix/actionCreators';
import {setLeftMenuActiveContent, showAppDimmer} from '../commonActions/commonAppActionCreators';

import {
    deleteFlowNodeFromTree,
    changeConditionBlocksPlaces,
    getContentToInsert,
    inputFirstElementInFlow,
    updateFlowAndMatrix,
    updateFlowNodeContent
} from '../../utils/flowTreeUtils';
import {getMatrixCellById, getParentCellId} from '../../utils/flowMatrixUtils';
import {removeEmptyBlocksFromAutoSavedFlow} from '../../utils/flowAutoSaveUtils';

import errorsMessagesConstants from '../../constants/flowValidationErrorMessages/errorsMessagesConstants';
import {deleteErrorsBlock, updateFlowErrors} from '../flowErrors/actionCreators';
import {SHOULD_SHOW_LEFT_MENU_DIMMER} from '../../reducers/common/appDimmerReducer';

import type {
    CompletedMatrixCellStateType,
    Dispatch,
    FlowBlockStateType,
} from '../../constants/flowTyped/flowTypedStates';

// Adds name to flow-tree
export const addFlowName = (name: string) => (dispatch: Dispatch) => {
    dispatch(updateFlowName(name));
};

const getActiveCell = () => applicationStore.getState().activeMatrixCell;

/* --------- FINAL METHOD----- */
// Adds first flow-tree node and calls next actions
export const createFirstFlowNode = (cellToInsert: CompletedMatrixCellStateType) => (dispatch: Dispatch) => {
    const {cellId, blockType} = cellToInsert;
    // Adds first flow-tree
    const newFlow = inputFirstElementInFlow(cellToInsert);
    dispatch(addFirstFlowElement(newFlow));

    // Update matrix after flow node insert
    updateFlowMatrixAfterNodeInserting(cellToInsert)(dispatch);

    // Change left Menu content
    if (blockType) {
        dispatch(setLeftMenuActiveContent(blockType));
    }
    if (getActiveCell().cellId !== cellId) {
        dispatch(setCellActive(cellToInsert));
    }
    dispatch(showAppDimmer(SHOULD_SHOW_LEFT_MENU_DIMMER));
};

export const addFlowNode = (flowTree: FlowBlockStateType, cellToInsert: CompletedMatrixCellStateType, blockType: ?string, nodeType: string) => (dispatch: Dispatch) => {
    dispatch(setLeftMenuActiveContent(null));
    const parentFlowNodeId = getParentCellId(cellToInsert);
    const contentToInsert = {[nodeType]: getContentToInsert(cellToInsert, blockType)};
    const newFlowTree = updateFlowNodeContent(flowTree, contentToInsert, parentFlowNodeId);
    if (newFlowTree) {
        dispatch(updateFlowBlock(newFlowTree));
        updateFlowMatrixAfterNodeInserting(cellToInsert)(dispatch);
        if (blockType) {
            dispatch(setLeftMenuActiveContent(blockType));
        }
    }
    const activeCell = getActiveCell();
    if (activeCell.cellId !== cellToInsert.cellId) {
        dispatch(setCellActive(cellToInsert));
    }
    if (!blockType) {
        const error = applicationStore.getState().flowErrors.find(error => error.cellId === parentFlowNodeId);
        if (error && error.messages.includes(errorsMessagesConstants.ACTION_IS_MISSING) && parentFlowNodeId) {
            if (error.messages.length === 1) {
                dispatch(deleteErrorsBlock(parentFlowNodeId));
            } else {
                dispatch(updateFlowErrors([errorsMessagesConstants.ACTION_IS_MISSING], parentFlowNodeId));
            }
        }
    }
};

export const updateFlowNode = (flowTree: FlowBlockStateType, flowNodeId: number, contentToUpdate: Object, dontUpdateActiveCell?: boolean) => (dispatch: Dispatch) => {
    const newFlowTree = updateFlowNodeContent(flowTree, contentToUpdate, flowNodeId);
    if (newFlowTree) {
        dispatch(updateFlowBlock(newFlowTree));
        if (contentToUpdate.blockType) {
            updateFlowMatrixCell(flowNodeId, 'blockType', contentToUpdate.blockType)(dispatch);
            dispatch(setLeftMenuActiveContent(contentToUpdate.blockType));
        }
        if (!dontUpdateActiveCell && getActiveCell().cellId !== flowNodeId) {
            const newActiveCell = getMatrixCellById(flowNodeId);
            if (newActiveCell) {
                dispatch(setCellActive(newActiveCell));
            }
        }
    }
};

export const deleteCurrentFlowNode = (matrixCell: CompletedMatrixCellStateType, flowNode: FlowBlockStateType) => (dispatch: Dispatch) => {
    const parentCellId = getParentCellId(matrixCell);
    const newFlowTree = deleteFlowNodeFromTree(parentCellId, flowNode.flowNodeId);
    if (newFlowTree) {
        dispatch(updateFlowBlock(newFlowTree));
        dispatch(setLeftMenuActiveContent(null));
        dispatch(refreshActiveCell());
        updateFlowMatrixAfterDeleteFlowNode(matrixCell, parentCellId)(dispatch);
    }
};

export const exchangePlacesConditions = (flowNodeId: number) => async (dispatch: Dispatch) => {
    await dispatch(refreshActiveCell());
    const {flow} = applicationStore.getState();
    let newBlock = changeConditionBlocksPlaces(flow.block, flowNodeId);
    newBlock = removeEmptyBlocksFromAutoSavedFlow(newBlock);
    const newFlowToUpdate = {...flow, block: {...newBlock}};
    const {newFlow, updatedFlowMatrix} = updateFlowAndMatrix(newFlowToUpdate);
    dispatch(addFirstFlowElement(newFlow));
    dispatch(fullMatrixUpdate(updatedFlowMatrix));
};
