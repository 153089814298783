// @flow

import {contactsDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {ContactsACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import {ContactStateType} from '../../constants/flowTyped/flowTypedStates';

export const setCustomers = (payload: ContactStateType): ContactsACTypes => ({
    type: contactsDispatchTypes.SET_CUSTOMER_DATA,
    payload
});

export const updateCustomers = (payload: ContactStateType): ContactsACTypes => ({
    type: contactsDispatchTypes.UPDATE_CUSTOMER_DATA,
    payload
});

export const setEmployees = (payload: ContactStateType): ContactsACTypes => ({
    type: contactsDispatchTypes.SET_EMPLOYEE_DATA,
    payload
});

export const updateEmployees = (payload: ContactStateType): ContactsACTypes => ({
    type: contactsDispatchTypes.UPDATE_EMPLOYEE_DATA,
    payload
});

export const setVendors = (payload: ContactStateType): ContactsACTypes => ({
    type: contactsDispatchTypes.SET_VENDOR_DATA,
    payload
});

export const updateVendors = (payload: ContactStateType): ContactsACTypes => ({
    type: contactsDispatchTypes.UPDATE_VENDOR_DATA,
    payload
});
