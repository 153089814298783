import QBOLineDetail from './QBOLineDetail';

class QBODepositLineDetail extends QBOLineDetail {
    accountRef: Object;
    entity: Object;

    accountRef = {
        value: {
            name: 'value',
            label: 'Account',
            placeholder: 'Select account',
            type: 'string',
            isRequired: true
        }
    };

    entity = {
        value: {
            name: 'value',
            label: 'Received from',
            type: 'string',
            placeholder: 'Received from'
        }
    };

    getFields() {
        return {
            ...super.getFields(),
            accountRef: this.accountRef,
            entity: this.entity
        };
    }
}

export default QBODepositLineDetail;