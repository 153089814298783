// @flow

import {flowActionsDispatchTypes, flowConditionsDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {
    BlockConditionStateType,
    ConditionFieldsState,
    ConditionFieldStateType,
    RecipientType,
} from '../../constants/flowTyped/flowTypedStates';
import type {
    ActiveActionACType,
    ActiveConditionACType,
    ConditionFieldsACType
} from '../../constants/flowTyped/flowTypedACTypes';

// Actions
export const setActiveActionType = (actionType: string): ActiveActionACType => ({
    type: flowActionsDispatchTypes.SET_FLOW_ACTION_TYPE,
    actionType
});

export const setActiveActionRecipient = (recipientType: string): ActiveActionACType => ({
    type: flowActionsDispatchTypes.SET_FLOW_ACTION_RECIPIENT,
    recipientType
});

export const setFullActionRecipient = (recipient: RecipientType): ActiveActionACType => ({
    type: flowActionsDispatchTypes.SET_FULL_FLOW_ACTION_CONTACT,
    recipient
});

export const setActiveActionRecipientContactInfo = (contact: string): ActiveActionACType => ({
    type: flowActionsDispatchTypes.SET_FLOW_ACTION_RECIPIENT_CONTACT_INFO,
    contact
});

export const setActiveActionText = (text: string): ActiveActionACType => ({
    type: flowActionsDispatchTypes.SET_FLOW_ACTION_TEXT,
    text
});

export const setActiveActionSubject = (subject: string): ActiveActionACType => ({
    type: flowActionsDispatchTypes.SET_FLOW_ACTION_SUBJECT,
    subject
});

export const setActiveActionData = (newActionState: ?Object): ActiveActionACType => ({
    type: flowActionsDispatchTypes.SET_FLOW_ACTION_DATA,
    newActionState
});

export const setAttachEntity = (attach: boolean): ActiveActionACType => ({
    type: flowActionsDispatchTypes.SET_ATTACH_ENTITY,
    attach
});

export const setCursorPosition = (cursorPosition: number = 0, positionCursorOfRow: number = 0): ActiveActionACType => ({
    type: flowActionsDispatchTypes.SET_CURSOR_POSITION,
    payload: {cursorPosition, positionCursorOfRow}
});

export const setTextAndCursor = (text: string, cursorPosition: number = 0, positionCursorOfRow: number = 0): ActiveActionACType => ({
    type: flowActionsDispatchTypes.SET_TEXT_AND_CURSOR_POSITION,
    payload: {text, cursorPosition, positionCursorOfRow}
});

export const setProviderActionEntity = (entity: string): ActiveActionACType => ({
    type: flowActionsDispatchTypes.SET_PROVIDER_ACTION_ENTITY,
    entity
});

export const setProviderActionEntityAction = (entityAction: string): ActiveActionACType => ({
    type: flowActionsDispatchTypes.SET_PROVIDER_ACTION_ENTITY_ACTION,
    entityAction
});

// Conditions
export const setSelectEventConditionFieldsList = (conditionFields: ConditionFieldsState): ConditionFieldsACType => ({
    type: flowConditionsDispatchTypes.SET_FLOW_CONDITION_FIELDS,
    conditionFields
});

export const setFlowConditionPath = (fieldsPath: Array<ConditionFieldStateType>): ActiveConditionACType => ({
    type: flowConditionsDispatchTypes.SET_FLOW_CONDITION_PATH,
    fieldsPath
});

export const setActiveConditionOperand = (operand: string, shouldResetValue: boolean): ActiveConditionACType => ({
    type: flowConditionsDispatchTypes.SET_FLOW_CONDITION_OPERAND,
    payload: {operand, shouldResetValue}
});

export const setActiveConditionValue = (value: any): ActiveConditionACType => ({
    type: flowConditionsDispatchTypes.SET_FLOW_CONDITION_VALUE,
    value
});

export const setActiveConditionData = (newConditionState: ?BlockConditionStateType): ActiveConditionACType => ({
    type: flowConditionsDispatchTypes.SET_FLOW_CONDITION_DATA,
    newConditionState
});
