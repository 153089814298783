// @flow

class QBOLineDetail {
    classRef: Object;

    classRef = {
        value: {
            name: 'value',
            label: 'Class',
            type: 'string',
            placeholder: 'Select class'
        }
    };

    getFields() {
        return {
            classRef: this.classRef
        };
    }
}

export default QBOLineDetail;