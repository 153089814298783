// @flow

import {eventsDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {SelectedEventACTypes, EventsListACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import type {EventStateType} from '../../constants/flowTyped/flowTypedStates';

export const setEvents = (events: Array<EventStateType>): EventsListACTypes => ({
    type: eventsDispatchTypes.SET_EVENTS_DATA,
    events
});

export const setFlowEventEntity = (entity: string): SelectedEventACTypes => ({
    type: eventsDispatchTypes.SET_SELECTED_EVENT_ENTITY,
    entity
});

export const setFlowEventEntityAction = (selectedEvent: EventStateType): SelectedEventACTypes => ({
    type: eventsDispatchTypes.SET_SELECTED_EVENT_ACTION_TYPE,
    selectedEvent
});