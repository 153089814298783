// @flow

import QBOBasicContact from './QBOBasicContact';

class QBOEmployee extends QBOBasicContact {
    getFields() {
        const parentFields = super.getFields();
        const {address: primaryAddr, companyName, ...restFields} = parentFields;
        return {
            ...restFields,
            primaryAddr
        };
    }
}

export default QBOEmployee;