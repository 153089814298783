// @flow

import {leftFocusDispatchTypes, SET_INITIAL_STATE} from '../../constants/dispatch/dispatchTypes';
import type {FocusOnActiveCellACTypes} from '../../constants/flowTyped/flowTypedACTypes';

const initialState = false;

const focusOnActiveCell = (state: boolean | null = initialState, action: FocusOnActiveCellACTypes) => {
    switch (action.type) {
        case leftFocusDispatchTypes.SET_FOCUS_ON_ACTIVE_CELL:
            return action.isActive;
        case SET_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default focusOnActiveCell;