// @flow

import {flowMatrixDispatchTypes, SET_INITIAL_STATE} from '../../constants/dispatch/dispatchTypes';
import type {CompletedMatrixCellStateType} from '../../constants/flowTyped/flowTypedStates';
import type {ActiveMatrixCellACTypes} from '../../constants/flowTyped/flowTypedACTypes';

const initialState = {};

function activeMatrixCell(state: CompletedMatrixCellStateType, action: ActiveMatrixCellACTypes) {
    switch (action.type) {
        case flowMatrixDispatchTypes.SET_ACTIVE_MATRIX_CELL:
            return action.activeCell;
        case flowMatrixDispatchTypes.REFRESH_ACTIVE_CELL:
        case SET_INITIAL_STATE:
            return initialState;
        default:
            return state || initialState;
    }
}

export default activeMatrixCell;