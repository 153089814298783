// @flow

import QBOPurchase from './QBOPurchase';
// eslint-disable-next-line import/no-named-as-default-member
import QBOCashExpenseAccountLine from './QBOCashExpenseAccountLine';

class QBOCashExpense extends QBOPurchase {
    accountLine: Object;

    accountLine = {
        ...new QBOCashExpenseAccountLine().getFields(),
        accountLineName: 'accountBasedExpenseLineDetail'
    };

    getFields() {
        return {
            ...super.getFields(),
            accountLine: this.accountLine,
        };
    }
}

export default QBOCashExpense;