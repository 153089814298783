// @flow
import {contactsDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {ContactItemType} from '../../constants/flowTyped/flowTypedStates';
import type {ContactsACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import {ContactStateType} from '../../constants/flowTyped/flowTypedStates';
import {DEFAULT_COMMON_REDUCER_META} from '../../constants/common';

const initialState = {
    list: [],
    meta: DEFAULT_COMMON_REDUCER_META
};

const customersReducer = (state: ContactStateType = initialState, action: ContactsACTypes): Array<ContactItemType> => {
    switch (action.type) {
        case contactsDispatchTypes.SET_CUSTOMER_DATA: {
            const {meta, list} = action.payload;

            return {
                list: list || state.list,
                meta: {
                    ...state.meta,
                    ...meta,
                }
            };
        }
        case contactsDispatchTypes.UPDATE_CUSTOMER_DATA: {
            const {meta, list} = action.payload;

            return {
                list: [...state.list, ...(list || [])],
                meta: {
                    ...state.meta,
                    ...meta,
                }
            };
        }
        default:
            return state;
    }
};

export default customersReducer;