// @flow
import React from 'react';
import camelCase from 'lodash/camelCase';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import flattenDeep from 'lodash/flattenDeep';
// eslint-disable-next-line import/no-cycle
import CombineAccordionFieldComponent from '../components/fieldsAccordionComponents/CombineAccordionFieldComponent';
import SimpleAccordionFieldComponent from '../components/fieldsAccordionComponents/SimpleAccordionFieldComponent';

import {CONDITION_FIELD_TYPE_OBJECT, CONDITION_FIELD_TYPE_REFERENCE} from '../constants/menu/leftMenuContentConstants';

import type {ConditionFieldsState, ConditionFieldStateType} from '../constants/flowTyped/flowTypedStates';

const dataToRemoveByNameFromService = [
    'INV_START_DATE',
    'QTY_ON_HAND',
    'TYPE',
    'ASSET_ACCOUNT'
];

export const getDataForCheckByEntities = (field: ConditionFieldStateType, conditionFields: ConditionFieldsState) => {
    let dataForCheck = [];
    if (field.referenceEntities.length > 1) {
        field.referenceEntities.forEach(newField => {
            dataForCheck.push(conditionFields[newField]);
        });
        dataForCheck = uniqBy(flattenDeep(dataForCheck), 'name');
    } else {
        dataForCheck = conditionFields[field.referenceEntities[0]];
    }
    if (field.uiName === 'Service') {
        dataForCheck = dataForCheck.filter(field => !dataToRemoveByNameFromService.includes(field.name));
    }
    return dataForCheck;
};

const sendParentOrEntityText = (parent, field) => (
    parent
        ? `${parent}.${camelCase(field.uiName)}`
        : `${camelCase(field.uiName)}`
);

export const checkFieldLikeAccordion = (eventData: ?Array<ConditionFieldStateType>, parent: ?string, isFullRender: ?boolean) => (
    eventData && sortBy(sortBy(eventData, ['uiName']), [o => ([CONDITION_FIELD_TYPE_REFERENCE, CONDITION_FIELD_TYPE_OBJECT].includes(o.type))]).map(field => {
        if ([CONDITION_FIELD_TYPE_REFERENCE, CONDITION_FIELD_TYPE_OBJECT].includes(field.type)) {
            return <CombineAccordionFieldComponent key={field.id}
                                                   parent={sendParentOrEntityText(parent, field)}
                                                   field={field}
                                                   isFullRender={isFullRender}
            />;
        }
        return <SimpleAccordionFieldComponent key={field.id}
                                              entityText={sendParentOrEntityText(parent, field)}
                                              field={field}
                                              isFullRender={isFullRender}
        />;
    })
);

const CURRENT_COMPANY_STATIC_FIELD = 'currentCompany';
export const staticMessageFields = [CURRENT_COMPANY_STATIC_FIELD];
export const staticMessageFieldsContent = [
    {
        id: CURRENT_COMPANY_STATIC_FIELD,
        uiName: CURRENT_COMPANY_STATIC_FIELD,
        children: [
            {
                id: `${CURRENT_COMPANY_STATIC_FIELD}.name`,
                entityText: `${CURRENT_COMPANY_STATIC_FIELD}.name`,
                uiName: 'name',
            },
            {
                id: `${CURRENT_COMPANY_STATIC_FIELD}.email`,
                entityText: `${CURRENT_COMPANY_STATIC_FIELD}.email`,
                uiName: 'email',
            },
            {
                id: `${CURRENT_COMPANY_STATIC_FIELD}.phone`,
                entityText: `${CURRENT_COMPANY_STATIC_FIELD}.phone`,
                uiName: 'phone',
            }
        ]
    }
];