// @flow

const FLOW_NAME_IS_REQUIRED = 'Rule name is required.';
const FLOW_BLOCK_IS_INVALID = 'Rule block is invalid.';
const FIELDS_PATH_IS_EMPTY = 'Fields Path is empty.';
const CONDITION_OPERAND_IS_REQUIRED = 'Condition operand is required.';
const CONDITION_VALUE_IS_REQUIRED = 'Condition value is required.';
const ACTION_IS_MISSING = 'Each "Condition" block must have at least one "Action" block.';
const TYPE_IS_REQUIRED = 'Type of action is required.';
const RECIPIENT_IS_REQUIRED = 'Type of recipient is required.';
const VARIABLE_IS_REQUIRED = 'Contact info is required.';
const MESSAGE_IS_REQUIRED = 'Message is required.';
const EMAIL_IS_INVALID = 'E-mail is invalid';
const URL_IS_INVALID = 'URL is invalid';
const PROVIDER_ENTITY_ERROR = 'QuickBooks entity isn\'t selected';
const PROVIDER_ENTITY_ACTION_ERROR = 'Action for QuickBooks entity isn\'t selected';
const SYNDER_ENTITY_ERROR = 'Synder entity isn\'t selected';
const SYNDER_ENTITY_ACTION_ERROR = 'Action for Synder entity isn\'t selected';
const QBD_ENTITY_ERROR = 'QuickBooks Desktop entity isn\'t selected';
const QBD_ENTITY_ACTION_ERROR = 'Action for QuickBooks Desktop entity isn\'t selected';

export const PROVIDER_ACTION_ERROR = 'Some accounting provider actions have errors. Please check it and fix it.';

export default {
    FLOW_NAME_IS_REQUIRED,
    FLOW_BLOCK_IS_INVALID,
    FIELDS_PATH_IS_EMPTY,
    CONDITION_OPERAND_IS_REQUIRED,
    CONDITION_VALUE_IS_REQUIRED,
    ACTION_IS_MISSING,
    TYPE_IS_REQUIRED,
    RECIPIENT_IS_REQUIRED,
    VARIABLE_IS_REQUIRED,
    MESSAGE_IS_REQUIRED,
    EMAIL_IS_INVALID,
    URL_IS_INVALID,
    PROVIDER_ENTITY_ERROR,
    PROVIDER_ENTITY_ACTION_ERROR,
    SYNDER_ENTITY_ERROR,
    SYNDER_ENTITY_ACTION_ERROR,
    QBD_ENTITY_ERROR,
    QBD_ENTITY_ACTION_ERROR
};