// @flow
import {SET_DYNAMIC_RULES_FORM} from '../../constants/dispatch/dispatchTypes';
import {DynamicRuleFormListACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import type {RulesForm} from '../../constants/dynamicForm/dynamicFormTypes';

const initialState = null;

const dynamicFormReducer = (state: RulesForm | null = initialState, action: DynamicRuleFormListACTypes): RulesForm | null => {
    if (action.type === SET_DYNAMIC_RULES_FORM) {
        return action.dynamicRuleForm;
    }
    return state;
};

export default dynamicFormReducer;