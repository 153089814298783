// @flow

class QBOItem {
    name: Object;
    qtyOnHand: Object;
    sku: Object;
    description: Object;
    assetAccountRef: Object;
    purchaseCost: Object;
    unitPrice: Object;
    incomeAccountRef: Object;
    expenseAccountRef: Object;

    name = {
        name: 'name',
        label: 'Product name',
        type: 'string',
        isRequired: true,
        maxLength: 100
    };

    qtyOnHand = {
        name: 'qtyOnHand',
        label: 'Qty on hand',
        type: 'number',
        isRequired: false
    };

    sku = {
        name: 'sku',
        label: 'Sku',
        type: 'string',
        isRequired: false,
        maxLength: 100
    };

    description = {
        name: 'description',
        label: 'Description',
        type: 'string',
        isRequired: false,
        maxLength: 4000
    };

    assetAccountRef = {
        value: {
            name: 'value',
            label: 'Asset account',
            type: 'string',
            isRequired: true
        }
    };

    purchaseCost = {
        name: 'purchaseCost',
        label: 'Purchase cost',
        type: 'number',
        isRequired: false,
        minValue: 0
    };

    unitPrice = {
        name: 'unitPrice',
        label: 'Unit price',
        type: 'number',
        isRequired: false,
        minValue: 0
    };

    incomeAccountRef = {
        value: {
            name: 'value',
            label: 'Income account',
            type: 'string',
            isRequired: true
        }
    };

    expenseAccountRef = {
        value: {
            name: 'value',
            label: 'Expense account',
            type: 'string',
            isRequired: true
        }
    };

    type = {
        name: 'type',
        label: 'Type',
        type: 'string',
        isRequired: true
    };

    getFields() {
        return {
            name: this.name,
            qtyOnHand: this.qtyOnHand,
            sku: this.sku,
            description: this.description,
            assetAccountRef: this.assetAccountRef,
            purchaseCost: this.purchaseCost,
            unitPrice: this.unitPrice,
            incomeAccountRef: this.incomeAccountRef,
            expenseAccountRef: this.expenseAccountRef,
            type: this.type
        };
    }
}

export default QBOItem;