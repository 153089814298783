// @flow

import {leftMenuContentDispatchTypes, SET_INITIAL_STATE} from '../../constants/dispatch/dispatchTypes';
import type {ContentACType} from '../../constants/flowTyped/flowTypedACTypes';

const initialState = null;

const menuContentTypeReducer = (state: string | null = initialState, action: ContentACType) => {
    switch (action.type) {
        case leftMenuContentDispatchTypes.SET_LEFT_MENU_TYPE:
            return action.menuType;
        case SET_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default menuContentTypeReducer;