// @flow
/* Content left Menu constants */
const LEFT_MENU_TRIGGER_BLOCK = 'Trigger';
const LEFT_MENU_CONDITIONS_BLOCK = 'Condition';
const LEFT_MENU_ACTIONS_BLOCK = 'Action';
export const leftMenuContentConstants = {
    LEFT_MENU_TRIGGER_BLOCK,
    LEFT_MENU_CONDITIONS_BLOCK,
    LEFT_MENU_ACTIONS_BLOCK
};

/* -------------------- Condition menu block -------------- */

export const CONDITION_FIELD_TYPE_REFERENCE = 'REFERENCE';
export const CONDITION_FIELD_TYPE_OBJECT = 'OBJECT';
export const CONDITION_FIELD_TYPE_DATE = 'DATE';
export const CONDITION_FIELD_TYPE_NUMBER = 'NUMBER';
export const CONDITION_FIELD_TYPE_STRING = 'STRING';
export const CONDITION_FIELD_TYPE_REFERENCE_VALUE = 'REFERENCE_VALUE';
export const CONDITION_FIELD_TYPE_BOOLEAN = 'BOOLEAN';
export const CONDITION_FIELD_TYPE_ENUM = 'ENUM';
export const REFERENCE_CONDITION_FIELD_TYPES = [CONDITION_FIELD_TYPE_REFERENCE, CONDITION_FIELD_TYPE_OBJECT];

// Const for Payment reminder flow
export const PAYMENT_REMINDER_EVENT_TYPE = 'PAYMENT_REMINDER';
export const PAYMENT_REMINDER_OPERANDS = ['DAYS_BEFORE_TODAY', 'DAYS_AFTER_TODAY'];

// Const for no value operands (set / no set)
export const NO_VALUE_IS_NULL_OPERAND = 'IS_NULL';
export const NO_VALUE_IS_NOT_NULL_OPERAND = 'IS_NOT_NULL';
export const CLEAN_NO_VALUE_OPERAND = 'Clean selected...';
export const CLEAN_DROPDOWN_OPTION = {
    key: CLEAN_NO_VALUE_OPERAND,
    value: CLEAN_NO_VALUE_OPERAND,
    text: CLEAN_NO_VALUE_OPERAND
};
export const NO_VALUE_OPERANDS = [NO_VALUE_IS_NULL_OPERAND, NO_VALUE_IS_NOT_NULL_OPERAND];
export const NO_VALUE_OPERANDS_DROPDOWN_VALUES = [
    CLEAN_DROPDOWN_OPTION,
    {key: NO_VALUE_IS_NULL_OPERAND, value: NO_VALUE_IS_NULL_OPERAND, text: 'Not set'},
    {key: NO_VALUE_IS_NOT_NULL_OPERAND, value: NO_VALUE_IS_NOT_NULL_OPERAND, text: 'Set'}
];

export const conditionBooleanDropdownValues = [
    {key: 'Yes', value: 'true', text: 'Yes'},
    {key: 'No', value: 'false', text: 'No'}
];

/* -------------------- Action Menu block ------------------------- */

export const ACTION_TYPE_EMAIL = 'EMAIL';
export const ACTION_TYPE_SMS = 'SMS';
export const ACTION_TYPE_VOICE = 'VOICE';
export const ACTION_TYPE_WEB_REQUEST = 'WEB_REQUEST';
export const ACTION_TYPE_PROVIDER_ACTION = 'PROVIDER_ACTION';

export const ACTION_RECIPIENT_TYPE_COMPANY_OWNER = 'COMPANY_OWNER';
export const ACTION_RECIPIENT_TYPE_CUSTOMER = 'CUSTOMER';
export const ACTION_RECIPIENT_TYPE_VENDOR = 'VENDOR';
export const ACTION_RECIPIENT_TYPE_EMPLOYEE = 'EMPLOYEE';
export const ACTION_RECIPIENT_TYPE_OTHER = 'OTHER';
export const ACTION_RECIPIENT_CONTACT_QBO = 'QBO';
export const ACTION_RECIPIENT_CONTACT_QBD = 'QBD';
export const ACTION_RECIPIENT_CONTACT_SYNDER = 'SYNDER';

export const ACTION_ATTACH_PDF_ENTITIES = ['SALES_RECEIPT', 'INVOICE'];
export const NO_TEMPLATE_MESSAGE_SIDEBAR_ACTIONS = [ACTION_TYPE_EMAIL, ACTION_TYPE_WEB_REQUEST, ACTION_TYPE_PROVIDER_ACTION];

export const LEFT_MENU_ELEMENT_ID = 'all-left-menu-sidebar';
