import {combineReducers} from 'redux';
import accounts from './accountsListReducer';
import classes from './classesListReducer';
import departments from './departmentsListReducer';
import paymentMethods from './paymentMethodsListReducer';
import providerSettings from './providerCompanySettingsReducer';
import terms from './termsListReducer';
import taxCodes from './taxCodesReducer';
import taxCalculationTypes from './taxCalculationTypesReducer';

export default combineReducers({
    accounts,
    classes,
    departments,
    paymentMethods,
    providerSettings,
    terms,
    taxCodes,
    taxCalculationTypes
});