// @flow

import {combineReducers} from 'redux';
import {reducer as form} from 'redux-form';

// import {mailbuilderReducer} from '@cloudbusiness/mailbuilder/lib';

import auth from './common/authReducer';
import contacts from './contacts/contactsCombinedReducer';
import events from './events/eventsCombinedReducer';
import templateGroups from './templateGroups/templateGroupsListReducer';
import providerData from './providerData/providerDataCombinedReducer';

// eslint-disable-next-line import/no-cycle
import flow from './flowTree/flowReducer';
import flowErrors from './flowErrors/flowErrorsReducer';
import flowOperations from './flowOperations/flowOperationsCombinedReducer';
import flowTemplates from './flowTemplates/flowTemplatesCombinedReducer';

import activeMatrixCell from './flowMatrix/activeMatrixCellReducer';
// eslint-disable-next-line import/no-cycle
import flowMatrix from './flowMatrix/flowMatrixReducer';
import focusOnActiveCell from './common/focusOnActiveCellReducer';

import appDimmers from './common/appDimmerReducer';
import appSideBars from './common/appSideBarsReducer';
import leftMenuContent from './common/menuContentTypeReducer';
import modals from './common/modalsReducer';
import showHints from './common/newbieReducer';
import transactionPlaceholdersModal from './common/transactionPlaceholdersModalReducer';
import spinner from './common/spinnerReducer';
import currentProvider from './common/currentProviderReducer';
import products from './products/productsReducer';
import dynamicRuleForm from './dynamicForm/dynamicFormReducer';
import userDetails from './common/userDetailsReducer';

export default combineReducers({
    auth,
    userDetails,

    contacts,
    products,
    events,
    templateGroups,
    providerData,

    flow,
    flowOperations,
    flowErrors,
    flowTemplates,

    activeMatrixCell,
    focusOnActiveCell,
    flowMatrix,

    appDimmers,
    appSideBars,
    leftMenuContent,
    modals,
    showHints,
    transactionPlaceholdersModal,
    spinner,

    form,

    dynamicRuleForm,

    currentProvider,
    // mailbuilderReducer
});
