// @flow

import QBOSalesTransaction from './QBOSalesTransaction';

class QBORefundReceipt extends QBOSalesTransaction {
    depositToAccountRef: Object;
    paymentMethodRef: Object;

    depositToAccountRef = {
        value: {
            name: 'value',
            label: 'Deposit to',
            placeholder: 'Select account',
            type: 'string',
            isRequired: false
        }
    };

    paymentMethodRef = {
        value: {
            name: 'value',
            label: 'Payment method',
            placeholder: 'Select payment method',
            type: 'string',
            isRequired: false
        }
    };

    getFields() {
        return {
            ...super.getFields(),
            paymentMethodRef: this.paymentMethodRef,
            depositToAccountRef: this.depositToAccountRef
        };
    }
}

export default QBORefundReceipt;