// @flow

class QBOBasicContact {
    // eslint-disable-next-line react/static-property-placement
    displayName: Object;
    givenName: Object;
    familyName: Object;
    companyName: Object;
    primaryPhone: Object;
    primaryEmailAddr: Object;
    address: Object;

    // eslint-disable-next-line react/static-property-placement
    displayName = {
        name: 'displayName',
        label: 'Display Name',
        type: 'string',
        isRequired: true,
        maxLength: 100
    };

    givenName = {
        name: 'givenName',
        label: 'First Name',
        type: 'string',
        maxLength: 50
    };

    familyName = {
        name: 'familyName',
        label: 'Last Name',
        type: 'string',
        maxLength: 50
    };

    primaryPhone = {
        freeFormNumber: {
            name: 'freeFormNumber',
            label: 'Phone',
            type: 'string',
            maxLength: 20
        }
    };

    primaryEmailAddr = {
        address: {
            name: 'address',
            type: 'string',
            subType: 'email',
            label: 'Email',
            maxLength: 100
        }
    };

    companyName = {
        name: 'companyName',
        label: 'Company name',
        type: 'string',
        isRequired: false,
        maxLength: 100
    };

    address = {
        line1: {
            name: 'line1',
            label: 'Line 1',
            type: 'string',
            maxLength: 500
        },
        country: {
            name: 'country',
            label: 'Country',
            type: 'string',
            maxLength: 255
        },
        city: {
            name: 'city',
            label: 'City',
            type: 'string',
            maxLength: 255
        },
        postalCode: {
            name: 'postalCode',
            label: 'Postal code',
            type: 'string',
            maxLength: 30
        },
        countrySubDivisionCode: {
            name: 'countrySubDivisionCode',
            label: 'Region / State',
            type: 'string',
            maxLength: 255
        }
    };

    getFields(): Object {
        return {
            displayName: this.displayName,
            givenName: this.givenName,
            familyName: this.familyName,
            primaryPhone: this.primaryPhone,
            companyName: this.companyName,
            primaryEmailAddr: this.primaryEmailAddr,
            address: this.address,
        };
    }
}

export default QBOBasicContact;