// @flow
import {flowActionsDispatchTypes, SET_INITIAL_STATE} from '../../constants/dispatch/dispatchTypes';
import type {BlockActiveActionStateType} from '../../constants/flowTyped/flowTypedStates';
import type {ActiveActionACType} from '../../constants/flowTyped/flowTypedACTypes';

const initialState = {
    recipient: {},
    message: {},
    cursorPosition: 0,
    positionCursorOfRow: 0
};

const flowActiveActionReducer = (state: BlockActiveActionStateType = initialState, action: ActiveActionACType) => {
    switch (action.type) {
        case flowActionsDispatchTypes.SET_FLOW_ACTION_TYPE:
            return {
                ...initialState,
                type: action.actionType,
            };
        case flowActionsDispatchTypes.SET_FLOW_ACTION_RECIPIENT:
            return {
                ...state,
                recipient: {
                    type: action.recipientType
                }
            };
        case flowActionsDispatchTypes.SET_FULL_FLOW_ACTION_CONTACT:
            return {
                ...state,
                recipient: action.recipient
            };
        case flowActionsDispatchTypes.SET_FLOW_ACTION_RECIPIENT_CONTACT_INFO:
            return {
                ...state,
                recipient: {
                    ...state.recipient,
                    contact: action.contact
                }
            };
        case flowActionsDispatchTypes.SET_FLOW_ACTION_SUBJECT:
            return {
                ...state,
                message: {
                    ...state.message,
                    subject: action.subject
                }
            };
        case flowActionsDispatchTypes.SET_FLOW_ACTION_TEXT:
            return {
                ...state,
                message: {
                    ...state.message,
                    text: action.text
                }
            };
        case flowActionsDispatchTypes.SET_FLOW_ACTION_DATA:
            return action.newActionState || initialState;
        case flowActionsDispatchTypes.SET_ATTACH_ENTITY:
            return {
                ...state,
                attachEntity: action.attach
            };
        case flowActionsDispatchTypes.SET_CURSOR_POSITION: {
            const {cursorPosition, positionCursorOfRow} = action.payload;
            return {
                ...state,
                cursorPosition,
                positionCursorOfRow
            };
        }
        case flowActionsDispatchTypes.SET_TEXT_AND_CURSOR_POSITION: {
            const {text, cursorPosition, positionCursorOfRow} = action.payload;
            return {
                ...state,
                message: {
                    ...state.message,
                    text
                },
                cursorPosition,
                positionCursorOfRow
            };
        }
        case flowActionsDispatchTypes.SET_PROVIDER_ACTION_ENTITY:
            return {
                ...state,
                entity: action.entity,
                entityAction: '',
                message: {}
            };
        case flowActionsDispatchTypes.SET_PROVIDER_ACTION_ENTITY_ACTION:
            return {
                ...state,
                entityAction: action.entityAction,
                message: {}
            };
        case SET_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default flowActiveActionReducer;
