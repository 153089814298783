// @flow

import applicationStore from '../../applicationStore';
import {fullMatrixUpdate, setCellActive, updateFlowMatrixCells} from './actionCreators';
import {
    getCenterElement,
    getCellNeighbors,
    matrixUpdateFunctionNames,
    refreshMatrixAfterNodeInserting,
    updateMatrixCells
} from '../../utils/flowMatrixUtils';
import {setLeftMenuActiveContent} from '../commonActions/commonAppActionCreators';

import type {
    CompletedMatrixCellStateType,
    Dispatch,
    FlowMatrixStateType,
    MatrixCellStateType,
    MatrixCellRowStateType
} from '../../constants/flowTyped/flowTypedStates';

/* --------- FINAL METHOD----- */
// It adds start flow tree position
export const addFlowMatrixStartCell = () => (dispatch: Dispatch) => {
    const matrixCenter: ?CompletedMatrixCellStateType = getCenterElement();
    if (matrixCenter) {
        const args = {id: matrixCenter.cellId, field: 'blockType', value: 'Start'};
        const updatedMatrix: Array<MatrixCellRowStateType> = updateMatrixCells(matrixUpdateFunctionNames.UPDATE_CELL_FIELD, args);
        dispatch(updateFlowMatrixCells(updatedMatrix));
        dispatch(setCellActive(matrixCenter));
    }
};

/* --------- FINAL METHOD----- */
// It adds variants for inserting new flow-tree node
export const addActiveNeighbors = (parentCell: MatrixCellStateType, parentCellBlockType: string, blockType: string, conditionType: string) => (dispatch: Dispatch) => {
    const parentCellNeighbors = getCellNeighbors(parentCell);
    const args: Object = {parentCellBlockType, parentCellNeighbors};
    if (blockType) {
        args.blockType = blockType;
    }
    if (conditionType) {
        args.conditionType = conditionType;
    }
    const updatedMatrix: Array<MatrixCellRowStateType> = updateMatrixCells(matrixUpdateFunctionNames.SET_CELLS_ACTIVE_TO_INSERT, args);
    dispatch(updateFlowMatrixCells(updatedMatrix));
};

/* --------- FINAL METHOD----- */
// Update flow matrix after new flow-tree node inserting
export const updateFlowMatrixAfterNodeInserting = (cellToInsert: CompletedMatrixCellStateType) => (dispatch: Dispatch) => {
    const args = {cellToInsert};
    const updatedFullMatrix: FlowMatrixStateType = refreshMatrixAfterNodeInserting(args);
    dispatch(fullMatrixUpdate(updatedFullMatrix));
};

export const updateFlowMatrixCell = (id: number, field: string, value: string) => (dispatch: Dispatch) => {
    const args: Object = {id, field, value};
    const updatedMatrix: Array<MatrixCellRowStateType> = updateMatrixCells(matrixUpdateFunctionNames.UPDATE_CELL_FIELD, args);
    dispatch(updateFlowMatrixCells(updatedMatrix));
};

/* --------- FINAL METHOD----- */
export const changeActiveCell = (cell: CompletedMatrixCellStateType) => (dispatch: Dispatch) => {
    const {blockType} = cell;
    const activeMenuType: string = applicationStore.getState().leftMenuContent;
    if (blockType && activeMenuType !== blockType) {
        dispatch(setLeftMenuActiveContent(blockType));
    }
    dispatch(setCellActive(cell));
};

export const updateFlowMatrixAfterDeleteFlowNode = (cellToDelete: CompletedMatrixCellStateType, parentCellId: number) => (dispatch: Dispatch) => {
    const args = {cellToDelete, parentCellId};
    const updatedMatrix: Array<MatrixCellRowStateType> = updateMatrixCells(matrixUpdateFunctionNames.UPDATE_MATRIX_AFTER_FLOW_NODE_DELETING, args);
    dispatch(updateFlowMatrixCells(updatedMatrix));
};