// @flow

import {providerDataDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {ProviderCompanySettingsStateType} from '../../constants/flowTyped/flowTypedStates';
import type {ProviderDataACTypes} from '../../constants/flowTyped/flowTypedACTypes';

const initialState = {};

export default function (state: ProviderCompanySettingsStateType, action: ProviderDataACTypes) {
    if (action.type === providerDataDispatchTypes.SET_PROVIDER_COMPANY_SETTINGS) {
        return action.settings;
    }
    if (action.type === providerDataDispatchTypes.CLEAN_PROVIDER_DATA) {
        return initialState;
    }
    return state || initialState;
}