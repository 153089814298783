// @flow
import {flowConditionsDispatchTypes, SET_INITIAL_STATE} from '../../constants/dispatch/dispatchTypes';
import type {BlockConditionStateType} from '../../constants/flowTyped/flowTypedStates';
import type {ActiveConditionACType} from '../../constants/flowTyped/flowTypedACTypes';

const initialState = {
    fieldsChain: {fieldsPath: []},
    value: []
};

const flowActiveConditionReducer = (state: BlockConditionStateType = initialState, action: ActiveConditionACType) => {
    switch (action.type) {
        case flowConditionsDispatchTypes.SET_FLOW_CONDITION_PATH:
            return {
                fieldsChain: {
                    fieldsPath: action.fieldsPath
                },
                value: []
            };
        case flowConditionsDispatchTypes.SET_FLOW_CONDITION_OPERAND: {
            const {operand, shouldResetValue} = action.payload;
            if (shouldResetValue) {
                return {
                    fieldsChain: {fieldsPath: state.fieldsChain.fieldsPath},
                    operand,
                    value: []
                };
            }
            return {...state, operand};
        }
        case flowConditionsDispatchTypes.SET_FLOW_CONDITION_VALUE:
            return {
                ...state,
                value: action.value
            };
        case flowConditionsDispatchTypes.SET_FLOW_CONDITION_DATA:
            return action.newConditionState ? action.newConditionState : initialState;
        case SET_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default flowActiveConditionReducer;
