// @flow

import isEmpty from 'lodash/isEmpty';
import {getSessionStorageValue} from '../api/api';

export const TEMP_AUTO_SAVED_FLOW = 'temp_autoSaved_flow';

export const isStorageAvailable = (storageType: string) => {
    try {
        const storage = window[storageType];
        const testData = 'flowless_test_storage';
        storage.setItem(testData, testData);
        storage.removeItem(testData);
        return true;
    } catch (e) {
        return false;
    }
};

const isTempAutoSavedFlowBlockValid = (block: Object) => {
    const {blockType, statement, type} = block;
    if (blockType === 'Action') {
        return !!type;
    }
    if (blockType === 'Condition') {
        return statement && !isEmpty(statement.fieldsChain.fieldsPath);
    }
    return false;
};

const shouldLoadAutoSavedFlow = (parsedFlowBlock: ?Object) => {
    if (!parsedFlowBlock) {
        return false;
    }
    const {nextAction, nextBlockForTrue, nextBlockForFalse} = parsedFlowBlock;
    if (nextAction && isTempAutoSavedFlowBlockValid(nextAction)) {
        return true;
    }
    if (nextBlockForTrue && isTempAutoSavedFlowBlockValid(nextBlockForTrue)) {
        return true;
    }
    if (nextBlockForFalse && isTempAutoSavedFlowBlockValid(nextBlockForFalse)) {
        return true;
    }
    return false;
};

// eslint-disable-next-line
const isTempSavedCompanyIdActual = (tempSavedCompanyId: string) => tempSavedCompanyId == getSessionStorageValue('companyId');

export const getTempAutoSavedFlow = () => {
    if (!isStorageAvailable('localStorage')) {
        return null;
    }
    const localStorageFlowData = localStorage.getItem(TEMP_AUTO_SAVED_FLOW);
    const parsedAutoSavedFlow = localStorageFlowData && JSON.parse(localStorageFlowData);
    if (parsedAutoSavedFlow && isTempSavedCompanyIdActual(parsedAutoSavedFlow.companyId) && shouldLoadAutoSavedFlow(parsedAutoSavedFlow.block)) {
        return parsedAutoSavedFlow;
    }
    return null;
};

export const removeTempAutoSavedFlow = () => localStorage.removeItem(TEMP_AUTO_SAVED_FLOW);

export const removeEmptyBlocksFromAutoSavedFlow = (block: Object) => {
    const {nextAction, nextBlockForTrue, nextBlockForFalse} = block;
    const newBlock = block;
    if (nextAction) {
        newBlock.nextAction = isTempAutoSavedFlowBlockValid(nextAction) ? removeEmptyBlocksFromAutoSavedFlow(nextAction) : null;
    }
    if (nextBlockForTrue) {
        newBlock.nextBlockForTrue = isTempAutoSavedFlowBlockValid(nextBlockForTrue) ? removeEmptyBlocksFromAutoSavedFlow(nextBlockForTrue) : null;
    }
    if (nextBlockForFalse) {
        newBlock.nextBlockForFalse = isTempAutoSavedFlowBlockValid(nextBlockForFalse) ? removeEmptyBlocksFromAutoSavedFlow(nextBlockForFalse) : null;
    }
    return newBlock;
};