// @flow
import {eventsDispatchTypes, SET_INITIAL_STATE} from '../../constants/dispatch/dispatchTypes';
import type {SelectedEventACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import type {EventStateType} from '../../constants/flowTyped/flowTypedStates';

type State = {} | {entity: string} | EventStateType

const initialState = {};

const selectedEventReducer = (state: State = initialState, action: SelectedEventACTypes): State => {
    switch (action.type) {
        case eventsDispatchTypes.SET_SELECTED_EVENT_ENTITY:
            return {entity: action.entity};
        case eventsDispatchTypes.SET_SELECTED_EVENT_ACTION_TYPE:
            return action.selectedEvent;
        case SET_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default selectedEventReducer;