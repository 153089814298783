// @flow

import {flowTemplatesDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {FlowTemplatesACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import type {FlowTemplateStateType} from '../../constants/flowTyped/flowTypedStates';

const initialState = [];

const flowTemplatesList = (state: Array<FlowTemplateStateType> = initialState, action: FlowTemplatesACTypes): Array<FlowTemplateStateType> => {
    if (action.type === flowTemplatesDispatchTypes.SET_FLOW_TEMPLATES_LIST) {
        return action.flowTemplatesList;
    }
    return state;
};

export default flowTemplatesList;