// @flow

import {
    appSideBarsDispatchTypes,
    leftMenuContentDispatchTypes,
    spinnerTypes,
    SET_INITIAL_STATE,
    modalsDispatchTypes,
    dimmersDispatchTypes,
    newbieTypes,
    leftFocusDispatchTypes,
    AuthDispatchTypes,
    currentProviderDispatchTypes,
    transactionPlaceholdersModalDispatchTypes, UserDetailsDispatchTypes
} from '../../constants/dispatch/dispatchTypes';
import type {
    AppSideBarsACTypes,
    LeftMenuACTypes,
    ModalACTypes,
    SpinnerACTypes,
    SetInitialStateACTypes,
    DimmerACTypes,
    NewbieACTypes,
    FocusOnActiveCellACTypes,
    AuthACTypes,
    CurrentProviderACTypes,
    TransactionPlaceholdersModalACTypes, UserDetailsACTypes
} from '../../constants/flowTyped/flowTypedACTypes';
import type {AuthDetails, CurrentProviderStateType, UserDetails} from '../../constants/flowTyped/flowTypedStates';

// Temp comment - AC with fixed flowTypes

// Modal
export const showModal = (component: any, modalProps: Object): ModalACTypes => ({
    type: modalsDispatchTypes.SHOW_MODAL,
    payload: {component, modalProps}
});
export const closeModal = (): ModalACTypes => ({
    type: modalsDispatchTypes.HIDE_MODAL,
});

// Spinner
export const runSpinner = (action: string): SpinnerACTypes => ({
    type: spinnerTypes.RUN_SPINNER,
    payload: action
});
export const stopSpinner = (): SpinnerACTypes => ({
    type: spinnerTypes.STOP_SPINNER
});

// Current provider
export const setCurrentProvider = (provider: CurrentProviderStateType): CurrentProviderACTypes => ({
    type: currentProviderDispatchTypes.SET_PROVIDER_TYPE,
    provider
});

/* Left Menu */
export const setLeftMenuActiveContent = (menuType: ?string): LeftMenuACTypes => ({
    type: leftMenuContentDispatchTypes.SET_LEFT_MENU_TYPE,
    menuType
});

export const changeAppSideBarsIsShown = (changedSideBars: Object): AppSideBarsACTypes => ({
    type: appSideBarsDispatchTypes.SET_IS_SIDEBAR_SHOWN,
    changedSideBars
});

export const setInitialState = (): SetInitialStateACTypes => ({
    type: SET_INITIAL_STATE
});

export const showAppDimmer = (dimmerName: string): DimmerACTypes => ({
    type: dimmersDispatchTypes.SHOW_APP_DIMMER,
    dimmerName
});

export const hideAppDimmer = (): DimmerACTypes => ({
    type: dimmersDispatchTypes.HIDE_APP_DIMMER,
});

export const setNewbie = (value: boolean): NewbieACTypes => ({
    type: newbieTypes.SET_NEWBIE,
    value
});

export const setFocusOnActiveCell = (isActive: boolean): FocusOnActiveCellACTypes => ({
    type: leftFocusDispatchTypes.SET_FOCUS_ON_ACTIVE_CELL,
    isActive
});

/* User details */
export const setUserDetails = (payload: UserDetails): UserDetailsACTypes => ({
    type: UserDetailsDispatchTypes.SET_USER_DETAILS,
    payload
});

/* Auth */
export const setAuthDetails = (details: AuthDetails): AuthACTypes => ({
    type: AuthDispatchTypes.SET_AUTH_DETAILS,
    details
});

export const setTransactionPlaceholdersModal = (value: boolean): TransactionPlaceholdersModalACTypes => ({
    type: transactionPlaceholdersModalDispatchTypes.SET_TRANSACTION_PLACEHOLDERS_MODAL,
    value
});