import {PROVIDER_INTUIT} from './flowTyped/flowTypedStates';

export const DEFAULT_COMMON_REDUCER_META = {
    size: 20,
    page: 0,
    totalCount: 0
};

export const COMMON_ERROR_MESSAGE = 'Something\'s gone wrong. Please, try again';

export const COMMON_ENABLE_LOCATIONS_SETTING = 'This feature is not enabled in your QuickBooks. Please visit your' +
  ' QuickBooks Account and Settings -> Advanced -> Track locations to enable it';
export const COMMON_ENABLE_CLASS_SETTING = 'This setting depends on your QuickBooks preferences. Please visit your' +
  ' QuickBooks Account and Settings -> Advanced -> Track classes to enable your preferred class tracking method.';

export const TEMPLATE_AVAILABLE_PROVIDERS = [PROVIDER_INTUIT];

export const LIMITATION_OF_CLOCK = 'Please note: you can only save a rule with as many as 100 building blocks. If you need more blocks, you can create another rule with the same trigger.';

export const MAX_COUNT_BLOCKS = 100;
