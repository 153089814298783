// @flow

import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import applicationStore from '../../applicationStore';
import {apiRequest} from '../../api/api';

import {setActiveActionData, setActiveConditionData, setSelectEventConditionFieldsList} from './actionCreators';
import {updateFlowNode} from '../flowTree/actions';
import {getFlowNodeContent} from '../../utils/flowTreeUtils';

import {isStorageAvailable, TEMP_AUTO_SAVED_FLOW} from '../../utils/flowAutoSaveUtils';
import {
    CONDITION_FIELD_TYPE_OBJECT,
    CONDITION_FIELD_TYPE_REFERENCE
} from '../../constants/menu/leftMenuContentConstants';

import type {Dispatch, ConditionFieldStateType, FlowBlockStateType} from '../../constants/flowTyped/flowTypedStates';

const getFlowTree = () => applicationStore.getState().flow.block;

type ConditionFieldResponse = {
    fields: Array<ConditionFieldStateType>
};

type FieldsResponse = Array<ConditionFieldResponse>;

export const apiLoadSelectEventConditionFieldsList = (relatedEntitiesToLoad: Array<string>, alreadyLoadedEntities: Array<string>) => async (dispatch: Dispatch) => {
    const promises: Array<Object> = relatedEntitiesToLoad.map(relatedEntity => apiRequest(`/v1/statementField?relatedEntity=${relatedEntity}`));
    const relatedEntitiesFields: FieldsResponse = await Promise.all(promises);
    const nextEntityFieldsToLoad = [];
    let fieldsToAddInState = {};
    relatedEntitiesFields.forEach((fieldsResponse, i) => {
        if (fieldsResponse.fields) {
            const {fields} = fieldsResponse;
            fields.forEach(elem => {
                if ([CONDITION_FIELD_TYPE_REFERENCE, CONDITION_FIELD_TYPE_OBJECT].includes(elem.type) && !isEmpty(elem.referenceEntities)) {
                    elem.referenceEntities.forEach(el => {
                        if (!alreadyLoadedEntities.includes(el) && !relatedEntitiesToLoad.includes(el)) {
                            nextEntityFieldsToLoad.push(el);
                        }
                    });
                }
            });
            fieldsToAddInState = {
                ...fieldsToAddInState,
                [relatedEntitiesToLoad[i]]: fields,
            };
        } else {
            // TODO Add error catching
        }
    });
    dispatch(setSelectEventConditionFieldsList(fieldsToAddInState));
    if (!isEmpty(nextEntityFieldsToLoad)) {
        const entitiesWasLoaded = [...alreadyLoadedEntities, ...relatedEntitiesToLoad];
        await apiLoadSelectEventConditionFieldsList(uniq(nextEntityFieldsToLoad), entitiesWasLoaded)(dispatch);
    }
};

export const updateCurrentActiveCondition = (flowNodeId: number) => (dispatch: Dispatch) => {
    const flowNode = getFlowNodeContent(applicationStore.getState().flow.block, flowNodeId);
    const updateParams = flowNode && flowNode.statement ? flowNode.statement : null;
    dispatch(setActiveConditionData(updateParams));
};

export const updateCurrentActiveAction = (flowNodeId: number) => (dispatch: Dispatch) => {
    const flowNode = getFlowNodeContent(getFlowTree(), flowNodeId);
    let updateParams;
    if (flowNode) {
        const {type, recipient, message, attachEntity, entity, entityAction} = flowNode;
        updateParams = {
            type,
            recipient: recipient || {},
            message: message || {},
            attachEntity,
            entity,
            entityAction,
        };
    }
    dispatch(setActiveActionData(updateParams));
};

export const updateFlowNodeContentFromMenu = (flowNodeId: number, contentToUpdate: FlowBlockStateType) => (dispatch: Dispatch) => {
    updateFlowNode(getFlowTree(), flowNodeId, contentToUpdate, true)(dispatch);
    if (isStorageAvailable('localStorage')) {
        localStorage.setItem(TEMP_AUTO_SAVED_FLOW, JSON.stringify(applicationStore.getState().flow));
    }
};
