// @flow

import {flowErrorsDispatchTypes, SET_INITIAL_STATE} from '../../constants/dispatch/dispatchTypes';
import type {ErrorInBlockStateType} from '../../constants/flowTyped/flowTypedStates';
import type {FlowErrorsACType} from '../../constants/flowTyped/flowTypedACTypes';

const initialState = [];

function flowErrors(state: Array<ErrorInBlockStateType>, action: FlowErrorsACType) {
    switch (action.type) {
        case flowErrorsDispatchTypes.SAVE_FLOW_ERRORS:
            return action.errors;
        case flowErrorsDispatchTypes.UPDATE_FLOW_ERRORS_MESSAGES: {
            const {cellId, fixedErrors} = action;
            return state.map<ErrorInBlockStateType>(errorsBlock => {
                if (errorsBlock.cellId === cellId) {
                    return {
                        ...errorsBlock,
                        messages: errorsBlock.messages.filter(error => !fixedErrors.includes(error))
                    };
                }
                return errorsBlock;
            });
        }
        case flowErrorsDispatchTypes.DELETE_ERRORS_BLOCK: {
            const {cellId} = action;
            return state.filter(errorsBlock => errorsBlock.cellId !== cellId);
        }
        case SET_INITIAL_STATE:
            return initialState;
        default:
            return state || initialState;
    }
}

export default flowErrors;