// @flow

import {dimmersDispatchTypes} from '../../constants/dispatch/dispatchTypes';

import type {DimmerStateType} from '../../constants/flowTyped/flowTypedStates';
import type {DimmerACTypes} from '../../constants/flowTyped/flowTypedACTypes';

export const SHOULD_SHOW_LEFT_MENU_DIMMER = 'SHOULD_SHOW_LEFT_MENU_DIMMER';
const initialState = {};

function dimmers(state: DimmerStateType = initialState, action: DimmerACTypes) {
    switch (action.type) {
        case dimmersDispatchTypes.SHOW_APP_DIMMER:
            return {
                [action.dimmerName]: true
            };
        case dimmersDispatchTypes.HIDE_APP_DIMMER:
            return initialState;
        default:
            return state;
    }
}

export default dimmers;