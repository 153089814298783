// @flow

import {apiRequest, getSessionStorageValue, goToSessionEndPage} from '../../api/api';
import {
    setCustomers, setEmployees, setVendors,
    updateCustomers,
    updateEmployees,
    updateVendors
} from './actionContactsCreator';

import applicationStore from '../../applicationStore';

import {
    ACTION_RECIPIENT_TYPE_CUSTOMER, ACTION_RECIPIENT_TYPE_EMPLOYEE,
    ACTION_RECIPIENT_TYPE_VENDOR
} from '../../constants/menu/leftMenuContentConstants';
import {PROVIDER_INTUIT, PROVIDER_INTUIT_DESKTOP, PROVIDER_SYNDER} from '../../constants/flowTyped/flowTypedStates';

import type {Dispatch} from '../../constants/flowTyped/flowTypedStates';
import {
    CUSTOMER_NAME,
    DEPOSIT_NAME, EMPLOYEE_NAME, EXPENSES_NAME,
    VENDOR_NAME
} from '../../constants/providerAction/providerEntitiesConstants';
import {runSpinner} from '../commonActions/commonAppActionCreators';
// eslint-disable-next-line import/no-cycle
import {showErrorTooltip} from '../commonActions/commonAppActions';

export const getContactUrl = (contactType: string, eventType: string, socialTokenId: string, page: number, max: number, search?: string): string => {
    let url = `/v1/provider/contacts?entityType=${contactType}&eventType=${eventType}&socialTokenId=${socialTokenId}&page=${page}&max=${max}`;
    if (search) url += `&search=${search}`;
    return url;
};

export const apiLoadContactsInfo = () => async (dispatch: Dispatch) => {
    const socialTokenId = getSessionStorageValue('companyId');
    const {events, currentProvider} = applicationStore.getState();
    const typeOfEvent = events.selectedEvent.entity;
    if (socialTokenId) {
        dispatch(runSpinner('Loading data... Please, wait'));
        const apiRequests = [];
        if (currentProvider === PROVIDER_INTUIT) {
            apiRequests.push(apiRequest(getContactUrl(ACTION_RECIPIENT_TYPE_CUSTOMER, typeOfEvent, socialTokenId, 0, 10)));
            apiRequests.push(apiRequest(getContactUrl(ACTION_RECIPIENT_TYPE_EMPLOYEE, typeOfEvent, socialTokenId, 0, 10)));
            apiRequests.push(apiRequest(getContactUrl(ACTION_RECIPIENT_TYPE_VENDOR, typeOfEvent, socialTokenId, 0, 10)));
        }
        if (currentProvider === PROVIDER_SYNDER) {
            const contactType = typeOfEvent === DEPOSIT_NAME ? ACTION_RECIPIENT_TYPE_CUSTOMER : ACTION_RECIPIENT_TYPE_VENDOR;
            apiRequests.push(apiRequest(getContactUrl(contactType, typeOfEvent, socialTokenId, 0, 10)));
        }
        if (currentProvider === PROVIDER_INTUIT_DESKTOP) {
            // TODO: ADD REQUESTS
        }
        const result: Response = await Promise.all(apiRequests);
        const [customersResponse, employeesResponse, vendorsResponse] = result;
        if (customersResponse) {
            if (customersResponse.content) {
                if (currentProvider === PROVIDER_SYNDER && typeOfEvent === EXPENSES_NAME) {
                    dispatch(setVendors({
                        list: customersResponse.content,
                        meta: customersResponse.metaData
                    }));
                } else {
                    dispatch(setCustomers({
                        list: customersResponse.content,
                        meta: customersResponse.metaData
                    }));
                }
            } else {
                showErrorTooltip(currentProvider === PROVIDER_SYNDER ? 'Can\'t load customers list' : 'Can\'t load customers list from QuickBooks');
            }
        }
        if (employeesResponse) {
            if (employeesResponse.content) {
                dispatch(setEmployees({
                    list: employeesResponse.content,
                    meta: employeesResponse.metaData
                }));
            } else {
                showErrorTooltip('Can\'t load employees list from QuickBooks');
            }
        }
        if (vendorsResponse) {
            if (vendorsResponse.content) {
                dispatch(setVendors({
                    list: vendorsResponse.content,
                    meta: vendorsResponse.metaData
                }));
            } else {
                showErrorTooltip('Can\'t load vendors list from QuickBooks');
            }
        }
        dispatch(runSpinner('Preparing data...'));
    } else {
        goToSessionEndPage();
    }
};

export const apiLoadCustomersCommon = (contactType: string, page: number, search: string) => async (dispatch: Dispatch) => {
    const socialTokenId = getSessionStorageValue('companyId');
    const {events, currentProvider} = applicationStore.getState();
    const typeOfEvent = events.selectedEvent.entity;
    let response;
    const commonRequestArgs = [typeOfEvent, socialTokenId, page, 10, search];
    if ([PROVIDER_INTUIT, PROVIDER_INTUIT_DESKTOP].includes(currentProvider)) {
        response = await apiRequest(getContactUrl(contactType, ...commonRequestArgs));
    }
    if (currentProvider === PROVIDER_SYNDER) {
        const contactType = typeOfEvent === DEPOSIT_NAME ? ACTION_RECIPIENT_TYPE_CUSTOMER : ACTION_RECIPIENT_TYPE_VENDOR;
        response = await apiRequest(getContactUrl(contactType, ...commonRequestArgs));
    }

    if (response?.content && response?.metaData) {
        switch (contactType) {
            case CUSTOMER_NAME:
                dispatch(updateCustomers({
                    list: response.content,
                    meta: response.metaData
                }));
                break;
            case VENDOR_NAME:
                dispatch(updateVendors({
                    list: response.content,
                    meta: response.metaData
                }));
                break;
            case EMPLOYEE_NAME:
                dispatch(updateEmployees({
                    list: response.content,
                    meta: response.metaData
                }));
                break;
          // no default
        }

        return ({
            list: response.content,
            meta: response.metaData
        });
    }
};

export const apiLoadCustomerById = async (contactId: string, contactType: string) => {
    const socialTokenId = getSessionStorageValue('companyId');
    const response = await apiRequest(`/v1/provider/contacts/${contactId}?socialTokenId=${socialTokenId}&entityType=${contactType}`);
    if (response) {
        return response;
    }
};