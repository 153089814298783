// @flow
import moment from 'moment';

import {flowDispatchTypes, SET_INITIAL_STATE} from '../../constants/dispatch/dispatchTypes';
// eslint-disable-next-line import/no-cycle
import {getSessionStorageValue} from '../../api/api';
import type {FlowTreeStateType} from '../../constants/flowTyped/flowTypedStates';
import type {FlowTreeACTypes} from '../../constants/flowTyped/flowTypedACTypes';

const getInitialState = () => ({
    block: {},
    status: 'ENABLED',
    name: `New Rule ${moment().format('YYYYMMDD-HHmmss')}`,
    blockCount: 1
});

function flow(state: FlowTreeStateType, action: FlowTreeACTypes): FlowTreeStateType {
    switch (action.type) {
        case flowDispatchTypes.SET_FLOW_NAME:
            return {...state, name: action.name};
        case flowDispatchTypes.SET_FLOW_EVENT_ID:
            return {
                ...state,
                eventId: action.eventId,
                companyId: state.companyId || getSessionStorageValue('companyId')
            };
        case flowDispatchTypes.SET_FIRST_ELEMENT:
            return {
                ...action.newFlow,
                blockCount: action.customBlockCount || state.blockCount
            };
        case flowDispatchTypes.SET_FLOW_BLOCK:
            return {...state, block: action.newFlowBlock, blockCount: action.customBlockCount || state.blockCount};
        case flowDispatchTypes.SET_FLOW_ID:
            return {...state, id: action.id};
        case flowDispatchTypes.SET_FLOW_BLOCK_COUNT_INCREASE:
            return {...state, blockCount: state.blockCount + 1};
        case flowDispatchTypes.SET_FLOW_BLOCK_COUNT_DECREASE:
            return {...state, blockCount: state.blockCount - 1};
        case SET_INITIAL_STATE: {
            const {id, status, name} = state;
            return id ? {id, status, name, block: {}} : getInitialState();
        }
        default:
            return state || getInitialState();
    }
}

export default flow;