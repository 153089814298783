// @flow

import QBOLineDetail from './QBOLineDetail';

class QBOItemLineDetail extends QBOLineDetail {
    itemRef: Object;
    qty: Object;
    description: Object;
    serviceDate: Object;

    itemRef = {
        value: {
            name: 'value',
            label: 'Product/Service',
            placeholder: 'Select product or service',
            type: 'string',
            isRequired: true
        }
    };

    description = {
        name: 'description',
        label: 'Description',
        type: 'string'
    }

    serviceDate = {
        name: 'serviceDate',
        type: 'string',
    }

    qty = {
        name: 'qty',
        label: 'Quantity',
        type: 'number',
    };

    getFields() {
        return {
            ...super.getFields(),
            itemRef: this.itemRef,
            description: this.description,
            qty: this.qty,
            serviceDate: this.serviceDate
        };
    }
}

export default QBOItemLineDetail;