import QBOBasicTransaction from '../QBOBasicTransaction';
import QBODepositLineDetail from '../lineItems/QBODepositLineDetail';

class QBODeposit extends QBOBasicTransaction {
    productLine: Object;

    productLine = {
        ...new QBODepositLineDetail().getFields(),
        productLineName: 'depositLineDetail'
    };

    getFields() {
        return {
            privateNote: super.getFields().privateNote,
            productLine: this.productLine
        };
    }
}

export default QBODeposit;