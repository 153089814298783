// @flow

class QBOBasicTransaction {
    docNumber: Object;
    txnDate: Object;
    privateNote: Object;
    departmentRef: Object;

    docNumber = {
        name: 'docNumber',
        label: 'Number',
        type: 'string',
        isRequired: false,
        maxLength: 22
    };

    txnDate = {
        name: 'txnDate',
        label: 'Date',
        type: 'string',
        isRequired: false
    };

    privateNote = {
        name: 'privateNote',
        label: 'Memo',
        type: 'string',
        isRequired: false,
        maxLength: 4000
    };

    departmentRef = {
        value: {
            name: 'value',
            label: 'Location',
            placeholder: 'Select location',
            type: 'string',
        }
    };

    getFields() {
        return {
            docNumber: this.docNumber,
            txnDate: this.txnDate,
            privateNote: this.privateNote,
            departmentRef: this.departmentRef,
        };
    }
}

export default QBOBasicTransaction;