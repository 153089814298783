// @flow
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line import/no-cycle
import applicationStore from '../applicationStore';
import {setAuthDetails} from '../actions/commonActions/commonAppActionCreators';
import getEnvVariable from '../utils/getEnvVariable';

const URLS = getEnvVariable();
const {API, HOME, WEB, RULES_NODE_API} = URLS;

export const getSessionStorageValue = (key: string): string => sessionStorage.getItem(key) || '';

export const inspectletSecretKey = () => {
    const key = '420853669';
    return {
        getKey: () => key
    };
};

export const getUserJWTTokenParam = (key: string) => {
    const accessToken = getSessionStorageValue('flow_acc_tok');
    if (accessToken) {
        const decodedToken = jwtDecode(accessToken);
        return decodedToken && decodedToken[key];
    }
};

export const redirectToWeb = (uri?: string) => {
    window.location = uri ? `${WEB}${uri}` : WEB;
};

export const redirectToCreateFlow = () => {
    window.location = `${HOME}/createFlow`;
};

export const redirectToWebHome = () => {
    window.location = `${WEB}/rules/rules`;
};

export const goToSessionEndPage = () => {
    window.location = `${HOME}/end`;
};

const makeRequest = (uri: string, config: ?Object) => {
    const incomeHeaders = config && config.headers;
    const init = {
        ...config,
        headers: {
            ...incomeHeaders
        },
    };
    return fetch(uri, init)
        .then(response => {
            if (response.status === 200 || response.status === 400) {
                return Promise.resolve(response.json());
            }
            if (response.status === 204) {
                return Promise.resolve({success: true});
            }
            if (response.status === 401) {
                goToSessionEndPage();
            }
            return Promise.reject();
        }).catch(() => Promise.resolve({errorWith: 'serverError'}));
};

export async function apiSynderRequest(uri: string, config?: Object): Object {
    const accessToken = getSessionStorageValue('flow_acc_tok');
    const fullUrl = `${WEB}${uri}`;
    const initConfig = {
        ...config,
        headers: {
            ...(config && config.headers),
            Authorization: `Bearer ${accessToken}`,
        }
    };
    const response = await makeRequest(fullUrl, initConfig);
    return response;
}

export async function apiRequest(uri: string, config?: Object): Object {
    const accessToken = getSessionStorageValue('flow_acc_tok');
    if (!accessToken) {
        goToSessionEndPage();
    } else {
        const fullUrl = `${API}${uri}`;
        const initConfig = {
            ...config,
            headers: {
                ...(config && config.headers),
                Authorization: `Bearer ${accessToken}`,
            }
        };
        const response = await makeRequest(fullUrl, initConfig);
        return response;
    }
}

const apiJwtProtectedRequest = async (url: string, config?: Object) => {
    const accessToken = getSessionStorageValue('flow_acc_tok');
    if (accessToken) {
        const init = {
            ...config,
            headers: {
                ...(config && config.headers),
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const response = await makeRequest(url, init);
        return response;
    }
    applicationStore.dispatch(setAuthDetails({
        authenticated: false,
    }));
};

export async function apiFlowlessTemplatesRequest(url: string, config?: Object) {
    const fullUrl = `${RULES_NODE_API}${url}`;
    const fetchConfig = {
        ...config,
        credentials: 'include',
        headers: {
            ...(config && config.headers),
            'X-CSRF-Token': applicationStore.getState().auth.csrfFlowlessTemplatesToken || ''
        }
    };
    const response = await apiJwtProtectedRequest(fullUrl, fetchConfig);
    return response;
}
