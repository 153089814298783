// @flow
import {SET_TEMPLATE_GROUPS_DATA} from '../../constants/dispatch/dispatchTypes';
import type {TemplateGroupsListACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import type {TemplateGroupsStateType} from '../../constants/flowTyped/flowTypedStates';

const initialState = [];

const templateGroupsListReducer = (state: Array<TemplateGroupsStateType> = initialState, action: TemplateGroupsListACTypes): Array<TemplateGroupsStateType> => {
    if (action.type === SET_TEMPLATE_GROUPS_DATA) {
        return action.templateGroups;
    }
    return state;
};

export default templateGroupsListReducer;