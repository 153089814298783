// @flow

class QBOTransferTransaction {
    fromAccountRef = {
        value: {
            name: 'value',
            label: 'From Account',
            type: 'string',
            placeholder: 'Select account',
            notSameField: 'toAccountRef'
        }
    };

    toAccountRef = {
        value: {
            name: 'value',
            label: 'To Account',
            type: 'string',
            placeholder: 'Select account',
            notSameField: 'fromAccountRef'
        }
    };

    getFields() {
        return {
            fromAccountRef: this.fromAccountRef,
            toAccountRef: this.toAccountRef
        };
    }
}

export default QBOTransferTransaction;