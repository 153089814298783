// @flow

import {newbieTypes} from '../../constants/dispatch/dispatchTypes';
import type {NewbieACTypes} from '../../constants/flowTyped/flowTypedACTypes';

function showHints(state: boolean = true, action: NewbieACTypes) {
    if (action.type === newbieTypes.SET_NEWBIE) {
        return action.value;
    }
    return state;
}

export default showHints;