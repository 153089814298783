// @flow
import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import {setExternalFlowAsFlowTree, showErrorTooltip} from '../commonActions/commonAppActions';

import {apiFlowlessTemplatesRequest, getSessionStorageValue} from '../../api/api';
import {runSpinner, setInitialState, stopSpinner} from '../commonActions/commonAppActionCreators';

import applicationStore from '../../applicationStore';

import type {Dispatch, FlowTemplateResponseType} from '../../constants/flowTyped/flowTypedStates';

type Response = {
    errorWith?: string,
    templates?: Array<FlowTemplateResponseType>
}

export const apiLoadFlowTemplatesList = async () => {
    const res: Response = await apiFlowlessTemplatesRequest('/templates');
    if (res.templates) {
        return res.templates;
    }
    showErrorTooltip('Can\'t load server data. Please, try again later or contact our support-team');
};

export const loadFlowTemplate = (templateId: number) => async (dispatch: Dispatch) => {
    dispatch(runSpinner('Loading data... Please, wait'));
    const {flowTemplatesList} = applicationStore.getState().flowTemplates;
    const template = flowTemplatesList.find(flowTemplate => flowTemplate.id === templateId);
    if (template) {
        try {
            const parsedFlow = JSON.parse(template.flow);
            if (parsedFlow) {
                dispatch(setInitialState());
                await setExternalFlowAsFlowTree({
                    ...parsedFlow,
                    status: 'ENABLED',
                    templateId,
                    executed: false,
                    companyId: getSessionStorageValue('companyId'),
                    name: `New Rule ${moment().format('YYYYMMDD-HHmmss')}`
                }, true)(dispatch);
            } else {
                showErrorTooltip('This template is temporary unavailable for use. Please, select another one.');
            }
        } catch (e) {
            showErrorTooltip('This template is temporary unavailable for use. Please, select another one.');
        }
    } else {
        showErrorTooltip('This template is temporary unavailable for use. Please, select another one.');
    }
    dispatch(stopSpinner());
};