import {UserDetailsDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import {UserDetails} from '../../constants/flowTyped/flowTypedStates';
import {UserDetailsACTypes} from '../../constants/flowTyped/flowTypedACTypes';

const initialState = {};

const userDetailsReducer = (state: UserDetails = initialState, action: UserDetailsACTypes) => {
    if (action.type === UserDetailsDispatchTypes.SET_USER_DETAILS) {
        return {
            ...state,
            ...action.payload
        };
    }
    return state;
};

export default userDetailsReducer;