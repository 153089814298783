// @flow

import {providerDataDispatchTypes} from '../../constants/dispatch/dispatchTypes';
import type {ProviderDataACTypes} from '../../constants/flowTyped/flowTypedACTypes';
import type {TaxCalculationTypes} from '../../constants/flowTyped/flowTypedStates';

const initialState = [];

export default function (state: Array<TaxCalculationTypes> = initialState, action: ProviderDataACTypes) {
    if (action.type === providerDataDispatchTypes.SET_PROVIDER_DATA_TAX_CALCULATION_TYPES) {
        return action.taxCalculationTypes;
    }
    if (action.type === providerDataDispatchTypes.CLEAN_PROVIDER_DATA) {
        return initialState;
    }
    return state;
}